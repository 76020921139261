import {Pipe, PipeTransform} from "@angular/core";
import {formatNumber} from "@angular/common";

@Pipe({
  standalone: true, name: "formatTechSpecs"
})
/**
 * Format keys and values for tech specs
 */
export class formatTechSpecs implements PipeTransform  {
  transform(res: { [key: string]: any }): { [key: string]: string } {
    let formattedRes: any = {};
    let keys = Object.keys(res);
    for (let key of keys) {
      let value = res[key];
      // console.log(key, value);
      if ((!value && value !== 0) || value == 'NA') {
        continue;
      }
      // Skip array values
      if (Array.isArray(value)) {
        continue;
      }
      // Mask certain fields
      if ([
        "DataVersionNumber"
      ].indexOf(key) > -1) {
        // We do not want to output this
        continue;
      }
      switch (key) {
        case        "UnladenWeight":
          key = "Weight (unladen)";
          value = formatNumber(value, 'en', '1.0-0') + ' kg';
          break;
        case           "RigidArtic":
          key = "Rigid articulation";
          break;
        case           "BodyShape":
          key = "Body type";
          break;
        case           "PayloadVolume":
          key = "Payload Volume";
          value = formatNumber(value, 'en', '1.0-0') + ' m3';
          break;
        case "PayloadWeight":
          key = "Payload weight";
          value = formatNumber(value, 'en', '1.0-0') + ' kg';
          break;
        case           "Height":
          value = formatNumber(value, 'en', '1.0-0') + ' kg';
          break;
        case           "NumberOfDoors":
          key = 'Number of doors'
          break;
        case           "NumberOfSeats":
          key = "Seating capacity";
          break;
        case           "KerbWeight":
          key = "Kerb weight";
          value = formatNumber(value, 'en', '1.0-0') + ' kg';
          break;
        case           "GrossTrainWeight":
          key = "Gross Train Weight";
          value = formatNumber(value, 'en', '1.0-0') + ' kg';
          break;
        case           "FuelTankCapacity":
          key = "Fuel Tank Capacity";
          value = formatNumber(value, 'en', '1.0-0') + ' L';
          break;
        case           "LoadLength":
          key = "Load Length";
          value = formatNumber(value, 'en', '1.0-0') + ' mm';
          break;
        case           "WheelBase":
          key = "Wheel Base";
          value = formatNumber(value, 'en', '1.0-0') + ' mm';
          break;
        case           "CarLength":
          key = "Length";
          value = formatNumber(value, 'en', '1.0-0') + ' mm';
          break;
        case           "Width":
          value = formatNumber(value, 'en', '1.0-0') + ' mm';
          break;
        case           "NumberOfAxles":
          key = "Number of axles";
          break;
        case           "GrossVehicleWeight":
          key = "Kerb weight";
          value = formatNumber(value, 'en', '1.0-0') + ' kg';
          break;
        case           "GrossCombinedWeight":
          key = "Kerb weight";
          value = formatNumber(value, 'en', '1.0-0') + ' kg';
          break;

      }
      formattedRes[key] = value;
    }
    return formattedRes;
  }
}
