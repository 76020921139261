<h2 mat-dialog-title>To access the VIN, you must first confirm the following please.</h2>
<mat-dialog-content>
  <ul>
    <li>I confirm that I represent a dealership engaged in the buying and selling of vehicles.</li>
    <li>I confirm that I have the registered owners consent to view this information.</li>
    <li>I agree to not share this information unless legally permitted to do so by the owner.</li>
    <li>I agree to solely use this information in order to verify the authenticity of this vehicle.</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog(false)">Cancel</button>
  <span class="spacer"></span>
  <button mat-raised-button (click)="closeDialog(true)" color="primary">Agree and reveal</button>
</mat-dialog-actions>
