<div class="prodCard">
  <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>
  <table mat-table matSort matSortActive="updated" matSortDirection="desc" [dataSource]="dealers">
    <ng-container matColumnDef="id" >
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header>Updated</th>
      <td mat-cell *matCellDef="let element">{{ element.updated | date }}</td>
    </ng-container>

    <ng-container matColumnDef="name" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dealer</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let element">{{ element.address }}</td>
    </ng-container>
    <ng-container matColumnDef="postcode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Post Code</th>
      <td mat-cell *matCellDef="let element">{{ element.postcode }}</td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let element">
        @if (element.phone) {
          <a [href]="'tel:' + element.phone">{{ element.phone }}</a>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="nDoneDeal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ads on DoneDeal</th>
      <td mat-cell *matCellDef="let element" class="right">
        @if (element.donedeal_id) {
          <a matTooltip="Open the dealer's profile page. Opens in a new tab."
            [href]="'https://www.donedeal.ie/dealer/' + element.donedeal_id"
            target="_blank" rel="noopener noreferrer">{{ element.nDoneDeal ?? 0 }}</a>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="nCarZone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ads on CarZone</th>
      <td mat-cell *matCellDef="let element" class="right">
        @if (element.carzone_id) {
          <a matTooltip="Open the dealer's profile page. Opens in a new tab."
            [href]="'https://www.carzone.ie/stores/a/' + element.carzone_id"
            target="_blank" rel="noopener noreferrer">{{ element.nCarZone ?? 0 | number }}</a>
        }

      </td>
    </ng-container>
    <ng-container matColumnDef="nCarsIreland">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ads on CarsIreland</th>
      <td mat-cell *matCellDef="let element" class="right">
        @if (element.carsireland_id) {
          <a matTooltip="Open the dealer's profile page. Opens in a new tab."
            [href]="'https://www.carsireland.ie/car-dealers/county/l/' + element.carsireland_id"
            target="_blank" rel="noopener noreferrer">{{ element.nCarsIreland ?? 0 | number }}</a>
        }
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
  </table>
</div>
