import { Component } from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {UserService} from "../../../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-edit-pwd',
  standalone: true,
    imports: [
        FormsModule,
        MatButton,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        MatError,
        MatFormField,
        MatInput,
        MatLabel,
        ReactiveFormsModule
    ],
  templateUrl: './edit-pwd.component.html',
  styleUrl: './edit-pwd.component.scss'
})
export class EditPwdComponent {
  fbError: string | undefined;
  protected pwdForm: FormGroup;

  constructor(private userService: UserService, private dialog: MatDialog, private snack: MatSnackBar) {
    this.pwdForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
      currentPassword: new FormControl('', [Validators.required]),
    });
  }

  cancel() {
    this.dialog.closeAll();
  }

  async save() {
    if (this.pwdForm.valid && this.pwdForm.touched) {
      try {
        // Reauthenticate the user first
        await this.userService.reAuthenticate(this.pwdForm.value.currentPassword);
        const newPwd = this.pwdForm.value.newPassword;
        await this.userService.updatePwd(newPwd);
        this.snack.open("Your password was updated.")
        this.dialog.closeAll();
      } catch (e: any) {
        this.fbError =  UserService.errorText(e.code ?? e.message ?? 'Could not update the password');
        this.pwdForm.markAsUntouched();
      }
    }
  }
}
