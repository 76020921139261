import { Component, Inject, ViewChild, ViewContainerRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: "app-dialog",
  standalone: true,
  imports: [MatIcon],
  templateUrl: "./generic-dialog.component.html",
  styleUrl: "./generic-dialog.component.scss",
})
export class GenericDialogComponent {
  @ViewChild("dynamicComponentContainer", { read: ViewContainerRef, static: true })
  container: ViewContainerRef | undefined;

  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  headerTitle: string | undefined;

  ngOnInit() {
    const comp = this.container?.createComponent(this.data.component);
    (comp?.instance as any).data = this.data.data;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
