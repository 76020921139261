<ul>
  <li>We love cars but that is not enough</li>
  <li>We have bought, we have imported, we have sold</li>
  <li>We have imported real volumes of cars ourselves over the last 20 years</li>
  <li>We are also technologists with long track records building tech stacks at scale</li>
  <li>We feel your pain and we have built the solution</li>
  <li>We are data engineers and analysts</li>
  <li>We know you are sick of spending too much for bad data, bad deals and bad rates</li>
  <li>We know you are sick of badly designed sites</li>
  @if (localeService.locale == 'en-IE') {
    <li>We know you are sick of spending all your evenings searching multiple sites, comparing one against another, then checking Retail, then VRT, then VAT, then Customs, then Forex, then Shipping and then realising it is 11.30pm and your whole day or night is gone on crap or scrap</li>
  }
  <li>We are here to</li>
  <ul>
    <li>Save you Time</li>
    <li>Save you Money</li>
    <li>Help you Make More Money</li>
    <li>Be your Partner</li>
  </ul>
</ul>
