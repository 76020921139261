import {Component, Input, OnInit} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from "@angular/material/table";
import {ApiService} from "../../services/api.service";
import {DatePipe, NgIf} from "@angular/common";
import {Router} from "@angular/router";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {CommerceService} from "../../services/commerce.service";

interface Activity {
  ts: string;
  vrm: null | string;
  make: string;
  model: null | string;
  model_tfl: null | string;
  versionData: null | string; // assuming `null` or any other type data
  credits: string;
  endpoint: string;
  vehicle_id: null | string;
}

@Component({
  selector: 'app-user-activity',
  standalone: true,
  imports: [MatTable, MatColumnDef, MatHeaderCell, MatCell, MatHeaderCellDef, MatCellDef, DatePipe, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatSort, MatSortHeader, MatProgressSpinner, NgIf],
  templateUrl: './user-activity.component.html',
  styleUrl: './user-activity.component.scss'
})
export class UserActivityComponent implements OnInit {
  @Input() days: number = 365;
  @Input() entries: number = 100;
  @Input() endpoint: 'ccq' | 'cyc' | undefined;
  activity = new MatTableDataSource<Activity[]>([]);
  displayedColumns = ['ts', 'vrm', 'make'];
  loading = false;
  mobile: boolean = false;

  constructor(private apiService: ApiService, private router: Router) {
    if (window) {
      if (window.innerWidth < 700) {
        this.mobile = true;
      }
    }
    console.log('mobile', this.mobile)
  }

  async ngOnInit() {
    if (!this.endpoint && !this.mobile) {
      this.displayedColumns.push('type');
      this.displayedColumns.push('credits');
    }
    this.loading = true;
    const r = await this.apiService.userActivity(this.days, this.entries, this.endpoint);
    if (r?.success) {
      this.activity = new MatTableDataSource(r.data);
    }
    this.loading = false;
  }

  action(element: Activity) {
    let route: any[] = [];
    switch (element.endpoint) {
      case 'check/uk':
      case 'check/ie':
        route = ['/home/cyc', {url: element.vrm}];
        break;
      case 'search/vrt':
        route = ['/home/ccq', {url: element.vrm}];
        break;
    }
    if (route.length > 0) {
      this.router.navigate(route)
        .catch(e => console.error(e));
    }
  }

  getActivity(element: Activity): string {
    switch (element.endpoint) {
      case 'check/uk':
      case 'check/ie':
        return 'CYC';
      case 'search/vrt':
        return 'CCQ';
      default:
        return '';
    }
  }

  protected readonly CommerceService = CommerceService;
}

