<section class="tnc header">
<h1>Terms &amp; Conditions</h1>
</section>
<section class="tnc page-content">
<!--  <p>Welcome to our Terms &amp; Conditions Page</p>-->

  <p >Please review all information
    within this page as the details contained are presented to not only define our
    relationship but also to protect the technology, infrastructure, intellectual
    property and business interests as a whole of the Company.</p>

  <p >We have tried to keep things as
    easy as possible however using our services does come with the following
    straight forward conditions.</p>

  <p >Please do go through all
    information and if you have any comments or questions do please feel free to
    contact us.</p>

  <h3>Definitions</h3>

  <p >“The Customer” – any persons or
    entity desiring to or who ultimately does contract with the Company. The words
    “you”, “your”, “their” are used interchangeably.</p>

  <p >“The Company” – means
    {{localeService.companyName()}}
    (“the Company”, “we”, “us”, “our”).</p>

  <p >“VAT” – Value Added Tax or
    equivalent as may be defined from region to region</p>

  <p >“Digital Assets” – any online
    content, information, websites, or other digital items owned or operated by the
    Company or presented to the Customer from the Company</p>

  <p >“Permitted Use” – as defined in
    these Terms &amp; Conditions</p>

  <h3>Agreement</h3>

  <p >The Company agrees to provide
    services to the customer and licences the data to the customer for Permitted Use
    in accordance with these Terms and Conditions in return for the consideration
    from the Customer agreeing to pay Fees to the Company.</p>

  <h3>Acceptance of Terms &amp; Conditions and Variations</h3>

  <p >When visiting, accessing or
    otherwise using the site or digital assets of the Company on this and on each
    subsequent occasion the Customer will be deemed to have both read and accepted
    these Terms &amp; Conditions and the Customer accepts that they are and will be
    bound by them.</p>

  <p >The Company reserves the right
    to alter the Terms &amp; Conditions at any time, without notice to the Customer,
    and it is the Customers responsibility and obligation to check if changes have
    occurred. The Customers subsequent use after changes have bene made shall be
    taken as their acceptance of the most up to date Terms &amp; Conditions.</p>

  <p >Any suggested amendment(s) from
    the Customer to the Terms &amp; Conditions must be agreed in writing by the
    Company in advance and prior to any suggested amended terms which may come into
    effect.</p>

  <p >The Customer accepts that the
    Company may transfer its rights and obligations under these Terms &amp;
    Conditions to another organisation. The Company commits to informing the
    Customer in writing if such an event occurs for example by email, letter, sms, pop
    up, banner and/or notification within the logged in section on the website and
    or publicly on the website. The Company will always strive to ensure that the
    Customers rights under the contract will remain unaffected.</p>

  <p >If the Customer does not agree
    to abide by any or all of the Terms &amp; Conditions, then you, the Customer, must
    immediately stop using the site, digital assets and or services of the Company.</p>

  <h3>Fees to the Company</h3>

  <p  >The Customer agrees to
pay the Fees to the Company for its services. All sums due and owing under this
agreement to the Company are exclusive of VAT. The VAT is payable at the same
time as the sum to which it relates. </p>

  <p  >The Fees the Company
charges may either be transactional by nature in a once off or multi bundle
package or alternatively certain Fees may be charged on a subscription i.e.
recurring basis dependant on the product, package or access type the Customer selects.</p>

  <p  >If a Free Trial or Free
Access is made available to any product or service, by activating any Free
Trial the Customer will be deemed to have provided their acceptance to this
agreement in full and the Customer also agrees to abide by these Terms &amp;
Conditions.</p>

  <p  >For any Fees which occur
on a subscription basis, unless the Customer cancels their access to the
Company’s services before their payment date, the Customer will be deemed to
have authorised the Company to charge the subscription Fee to the Customers Payment
Method or any associated payment method on file on that date or at a subsequent
date thereafter.</p>

  <p  >The Company reserves the
right to revise the Fees annually or otherwise as and when it sees fit. If any
adjustment or alternation is made the Company agrees to provide at least 28
days’ notice in writing to the Customer. This communication may occur through
any written form which may include, for example, email, letter, sms, pop up,
banner and/or notification within the logged in section on the website and or
publicly on the website.</p>

  <p >For the Company offerings which
    occur on a subscription basis, if a payment fails to occur for whatever reason
    and the Customer does not update their payment method and settle the Fees the
    Company may (i) terminate its contract with the Customer; and/or (ii) suspend
    access to the service until the Fees are paid and the account brought to good
    standing. The Customer will remain responsible and liable for any outstanding
    sums owing.</p>

  <p >Refunds will not be processed
    for a subscription which continues monthly, quarterly, bi-annually or annually
    or any other duration without just cause, as specifically defined by incorrect
    pricing of products or services or in other specific circumstances outlined in
    the Terms &amp; Conditions. Failure to cancel any subscription services will
    not, by itself, create just cause grounds to merit a refund.</p>

  <p >Some services offered by the
    Company are once off purchases of digital content. The Company will make that
    digital content available for access as soon as the order is processed. Whilst
    for services in a business to consumer world and occasionally elsewhere
    customers have a legal right to change their mind within 14 days and receive a
    refund, the Customer acknowledge that they do not have a right to change their
    mind in respect of one off purchase or any other services or access types in
    scenarios when digital content has been provided or started to be provided to
    the Customer. Only in cases with just cause such as incorrect pricing of
    products or services or otherwise as specified in the Terms &amp; Conditions
    will refunds be permissible.</p>

  <h3>Disclaimer</h3>

  <p >No content, images, information,
    reports, services or any other information on this site or any other digital
    assets or services presented shall be held to have constituted an offer by the
    Company to provide its services to any person or entity accessing the same.</p>

  <p >All content, images,
    information, names, logos, icons, reports, services or any other information is
    provided on an “as is” and “as available” basis and the Company offers and thus
    gives no guarantees, warranties, conditions or representations, implied or
    express, that its websites, digital assets, functions, materials or services
    will be available without interruption, error, virus or being bug free or that
    it will do so without interruption or error.</p>

  <p >For the avoidance of doubt, any
    and all information provided digitally or otherwise, is intended to be general
    in nature and you, the Customer, must make your own decisions and not solely or
    substantially or materially base any decision on the information presented by
    the Company.</p>

  <p >Whilst the Company tries to
    ensure that all information provided is correct at the time of inclusion given
    the variety of data sources, complexity of processing the same and potential
    time lags and otherwise, the Company does not suggest nor give any representations
    or warranty, implied or expressed, about the validity, accuracy, completeness,
    relevance, merchantability or appropriateness for any particular purpose or
    non-infringement of any materials, information, graphics or materials on this
    site or any other assets of the Company.</p>

  <p >To the extent permitted by law,
    under no circumstances will the Company be liable (whether in Contract or Tort
    (including Negligence or Breach of Statutory Duty) or otherwise) for any losses
    sustained and arising out of or in connection with use of this website, any
    apps, any digital assets or services including, without limitation, loss of goodwill,
    loss of profits or loss of data, whether direct or indirect, nor any indirect,
    economic, consequential or special loss.</p>

  <p >The Customer agrees that no
    verbal or written representations, information, advice or communication given
    by the Company or its authorised representatives will create a warranty or in
    any way increase the scope of any warranty.</p>

  <p >For the avoidance of doubt it is
    reiterated that you, the Customer, are using the website and any and all
    products and services at your own risk, and save for what is provided for in
    the contractual terms relating to the provision of the Company’s services, the
    Company shall not be liable for any consequential, indirect or direct loss or
    damage resulting from your reliance on any information on this website or other
    assets, or your use of the same, or any other errors or omissions with the
    material contained on the site or other assets.</p>

  <h3>Copyright &amp; Other Rights</h3>

  <p >Unless and except where
    expressly set out on this website or the Company’s digital assets, the contents
    of the web pages, apps and or digital assets are © TheCarSite 2024 and TheCarSite
    is the proprietor of all other intellectual property rights in them.</p>

  <p >All TheCarSite names, logos and
    trademarks are the property and proprietary marks of TheCarsite or its related
    or associated companies. References to or marks of third parties are owned or licenced
    by those third parties or their associated companies. The name ‘TheCarsite’ and
    TheCarSite logo are registered trademarks of the Company.</p>

  <p >Nothing in these Terms &amp;
    Conditions confers in any way on the Customer any right or licences under any
    names, logos or trademarks of TheCarSite or any third party, and unauthorised
    use of any form may lead to legal action being taken.</p>

  <p >For the avoidance of doubt, the
    Company is the sole owner of any and all Intellectual Property (which may
    include all patents, rights to inventions, utility models, copyright and
    related rights, trademarks, service marks, trade, business and domain names,
    rights in trade dress or get-up, rights in goodwill or to sue for passing off,
    unfair competition rights, rights in designs, rights in computer software,
    database right, topography rights, moral rights, rights in confidential
    information (including know-how and trade secrets) and any other intellectual
    property rights, in each case whether registered or unregistered and including
    all applications for and renewals or extensions of such rights, and all similar
    or equivalent rights or forms of protection in any part of the world) in
    connection with our branding, services, databases and any reports (which
    includes the data and branding within the reports as well as the look, feel and
    format of the reports) is retained by the Company or its licensors, whichever the
    case may be. The Customer acknowledge that, in respect of any third party
    Intellectual Property Rights, their use of any such Intellectual Property
    Rights is conditional on the Company obtaining a licence from the relevant
    licensor on such terms as will entitle and allow the Company to license such
    rights to you.</p>

  <h3>Permitted Use &amp; Prohibited Acts</h3>

  <p >TheCarSite may permit you, the
    Customer, to access this site, apps or other digital assets. In consideration
    of the same you agree that you will not download, republish, copy, reproduce,
    redistribute, transfer, present, display, adapt, alter, create derivative works
    from or otherwise extract or attempt to extract or re-utilise any information
    or material appearing on this site, apps or other digital assets of the Company
    except where specific facilities have been provided for such a purpose and the
    Company has explicitly confirms its consent.</p>

  <p >For the avoidance of doubt, the
    Company does not permit the use of any automated or digitized software,
    application or tool to use the services, index, test, extract or otherwise
    retrieve information from the Company unless explicitly agreed in writing in
    advance with the Company. Nor does the company allow the Customer to reuse any
    materials, information or data generally for any other purposes than those
    outlined in this agreement, nor to download information in a manner that
    enables or allows for later offline retrieval without access the Company’s
    website and or database.</p>

  <p >The Customer further agrees not
    to create any copy of the information presented by the Company and also agrees
    not to allow, permit or otherwise enable any other person to do any of the
    above.</p>

  <p >The Customer agrees to only
    allow named representatives of their organisation to have access to the
    services of the Company.</p>

  <p >The Customer acknowledges and
    accepts that certain information, products or services may not be applicable in
    particular jurisdictions and that it is the Customers responsibility to
    confirm the same. The Customer also acknowledges and accepts that in first
    instance the Company is primarily focussed on the market from which vehicles
    are being imported as opposed to the domestic market where the Customer
    ultimately intends to sell the vehicle itself.</p>

  <h3>Website Availability</h3>

  <p >The
    Customer acknowledges that internet traffic and indeed usage as a whole may
    cause, result in and or encounter delays and even outages from time to time.
    The Customer agrees to not hold the Company liable for delays or outages that
    are ordinary in the course of internet usage. The Customer also accepts that
    the website cannot be expected to be continuously available every single day –
    errors can occur, bugs can develop, servers can go down, modifications may be
    needed, upgrades and even maintenance events do occur in the regular course of
    business.</p>



  <h3>Linked sites</h3>

  <p >The Company from time to time
    may present third party links to other websites. In such cases those other
    websites may be operated by parties other than the Company. The Company does
    not control such third party sites and is in no way responsible for their
    content. Inclusion of any third party links in no way implies nor suggests any
    endorsement of the information, material or statements contained on the website
    or of the owners or operators thereof.</p>

  <p >You may only link to this
    website from another website with the Companies express permission which the
    company reserves the right to withdraw at any time by giving notice in writing
    to you to remove any such link without delay.</p>

  <h3>Law</h3>

  <p >This website, any apps or other
    digital assets of the Company and the relationship with you as a Customer and
    these Terms &amp; Conditions as a whole are governed by and construed in
    accordance with English law, and you agree to submit to the exclusive
    jurisdiction of the English Court in relation to any matter arising under or in
    connect with your use of the website, any apps or other digital assets or the
    Companies contents and services in their entirety. Any dispute shall, unless
    otherwise expressly agreed, be subject to the exclusive jurisdiction of the
    courts of England.</p>


</section>
