import { Component } from "@angular/core";
import {Router} from "@angular/router";
import {BusinessDetailsFormComponent} from "../../components/business-details-form/business-details-form.component";
import {MatButton} from "@angular/material/button";


@Component({
  selector: "app-register-details",
  standalone: true,
  imports: [BusinessDetailsFormComponent, MatButton],
  templateUrl: "./register-details.component.html",
  styleUrl: "./register-details.component.scss",
})
export class RegisterDetailsComponent {
  constructor(private router: Router) {}

  next() {
    // Proceed to next step
    this.router.navigate(["/choose-product"]);
  }
}
