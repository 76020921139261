import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {VinRevealService} from "../../../services/vin-reveal.service";

@Component({
  selector: 'app-show-vin',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton
  ],
  templateUrl: './show-vin.component.html',
  styleUrl: './show-vin.component.scss'
})
export class ShowVinComponent {
  constructor(@Inject(MAT_DIALOG_DATA) private data: {vdc: VinRevealService}, private dialog: MatDialog) {
  }

  closeDialog(close: boolean) {
    this.data.vdc.showVin = close;
    this.dialog.getDialogById('showvin')?.close();
  }
}
