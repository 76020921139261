<section class="header">
  @if (success) {
    <div>
      <h1>Payment success</h1>
      <div class="prodCard">
        <h2>Thank your for subscribing!</h2>
        <p>Welcome to the TheCarSite!</p>
        <p>Your subscription is now active, and we're thrilled to have you with us.</p>
        <button mat-raised-button color="primary" routerLink="/home/start">Get started</button>
      </div>
    </div>
  } @else if (success === false) {
    <div>
      <h1>The payment failed</h1>
      <div class="prodCard">

        <h2>The payment could not be verified</h2>
        <p>We got the following error code:</p>
        <div class="error">{{ paymentError }}</div>
        <p>Please try again.</p>
        <button mat-raised-button color="primary" routerLink="/choose-product">Back to product selection</button>
<!--        <p>If you are having issues with the payment, feel free to <a routerLink="/contact">contact us</a>.</p>-->
      </div>
    </div>
  } @else {
    <div>
      <h1>Checking payment status</h1>
      <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
  }
</section>
