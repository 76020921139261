
<div class="reviewsContainer">
  <div class="scroll-indicators">
    <img ngSrc="/assets/icons/left.svg" width="16" height="16" alt="Previous">
    <img ngSrc="/assets/icons/right.svg" width="16" height="16" alt="Next">
  </div>
  <div class="carousel">

        @for (r of reviewGroups()[0]; track $index) {
          <div class="review">
            <!--        <div class="score">{{r.score | number: '1.1-1': 'en'}} / {{ 5.0 | number: '1.1-1': 'en'}}</div>-->
            <img class="score" ngSrc="/assets/img/stars-5.svg" width="512" height="96" alt="5 stars">
            <div class="text">{{ r }}</div>
          </div>
        }

  </div>

</div>
