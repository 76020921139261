<button class="hamburgerButton" mat-button [matMenuTriggerFor]="menu">
  <mat-icon>menu</mat-icon>
</button>
<mat-menu #menu="matMenu">
  @if (userService.isLoggedIn()) {
    <button mat-menu-item  routerLink="/home/ccq" [hidden]="!localeService.showService('ccq')">Calculate Cars Quicker</button>
    <button mat-menu-item  routerLink="/home/cyc" [hidden]="!localeService.showService('cyc')">Check Your Car</button>
    <button mat-menu-item  routerLink="/home/fcf" [hidden]="!localeService.showService('fcf')">Find Cars Faster</button>
    <button mat-menu-item  routerLink="/ffl" [hidden]="!localeService.showService('ffl')">Forex For Less</button>
    <button mat-menu-item  routerLink="/home/ctf" [hidden]="!localeService.showService('ctf')">Cars to Forecourt</button>
    <button mat-menu-item  routerLink="/home/activity">Recent Activity</button>
    <button mat-menu-item  routerLink="/home/admin" *ngIf="userService.admin">Admin</button>
    <mat-divider></mat-divider>
    <button mat-menu-item  routerLink="/home/account">Account</button>
    <button mat-menu-item   routerLink="/contact">Contact Us</button>
    <button mat-menu-item  (click)="userService.logout()">Log out</button>
  } @else {
    <button mat-menu-item  routerLink="/ccq" [hidden]="!localeService.showService('ccq')">Calculate Cars Quicker</button>
    <button mat-menu-item  routerLink="/cyc" [hidden]="!localeService.showService('cyc')">Check Your Car</button>
    <button mat-menu-item  routerLink="/fcf" [hidden]="!localeService.showService('fcf')">Find Cars Faster</button>
    <button mat-menu-item  routerLink="/tcspro" [hidden]="!localeService.showService('tcspro')">TheCarSite Pro</button>
    <button mat-menu-item  routerLink="/ffl" [hidden]="!localeService.showService('ffl')">Forex For Less</button>
    <mat-divider></mat-divider>
    <button mat-menu-item  routerLink="/about">About us</button>
    <button mat-menu-item  routerLink="/register">Sign up</button>
    <button mat-menu-item  routerLink="/login">Login</button>
  }
</mat-menu>
