import { Component } from "@angular/core";
import { MatButtonModule} from "@angular/material/button";
import { MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {Router} from "@angular/router";
import {MatActionList} from "@angular/material/list";
import {ApiService} from "../../services/api.service";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatActionList,
    NgIf,
    NgClass
  ],
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss"
})
export class LoginComponent {
  protected loginForm: FormGroup;
  forgotPwdError: string | null = null;
  pwdReminderSent: string | null = null;
  disablePwdReminder: boolean = false;
  constructor(protected userService: UserService, private router: Router, private apiService: ApiService) {
    this.loginForm = new FormGroup({
      "username": new FormControl("",[Validators.email,Validators.required]),
      "password": new FormControl("",[Validators.required])
    });
    this.loginForm.get(["username"])?.valueChanges.subscribe((value) => {
      this.disablePwdReminder = false;
      this.forgotPwdError = null;
    });
  }

  protected login() {
    if (!this.loginForm.valid) {
      return;
    }
    this.userService.login(this.loginForm.get("username")?.value,this.loginForm.get("password")?.value)
      .then(() => {
        this.router.navigate(["/home"]);
      });

  }

  forgotPwd() {
    this.forgotPwdError = null;
    this.pwdReminderSent = null;
    this.disablePwdReminder = true;
    const email = this.loginForm.get("username")?.value;
    if (!email) {
      this.forgotPwdError = "Please provide your e-mail above to receive an email to reset your password.";
      return;
    }
    this.userService.resetPwd(email)
      .then(() => {
        this.pwdReminderSent = `An e-mail was sent to ${email}`
      })
      .catch(e => {
        console.error(e);
        this.forgotPwdError = `We could not send an e-mail to ${email} reset the password: ${e.toString()}`;
      });
  }
}
