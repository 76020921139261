import {Component, OnInit} from "@angular/core";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {ApiService} from "../../../../services/api.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {DatePipe, NgIf} from "@angular/common";
import {UserService} from "../../../../services/user.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-users",
  standalone: true,
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatOption,
    MatRow,
    MatRowDef,
    MatSelect,
    MatTable,
    MatHeaderCellDef,
    MatProgressSpinner,
    NgIf,
    DatePipe,
    MatButton,
    MatIcon,
    MatTooltip,
  ],
  templateUrl: "./admin-users.component.html",
  styleUrl: "./admin-users.component.scss",
})
export class AdminUsersComponent implements OnInit {
  protected users = new MatTableDataSource([]);
  protected loading: boolean | undefined;
  displayedColumns_users = [
    "created",
    "lastLoginAt",
    "lastBill",
    "quota",
    "service",
    "validate",
    "email",
    "emailVerified",
    "role",
  ];
  constructor(private apiService: ApiService, private snack: MatSnackBar) {}
  async ngOnInit() {
    this.loading = true;
    await this.loadUsers();
  }

  private async loadUsers() {
    this.users = new MatTableDataSource(await this.apiService.getUsers());
    this.loading = false;
  }
  getRole(element: any): string {
    if (element.customClaims["admin"] === true) {
      return "admin";
    } else if (element.customClaims["editor"] === true) {
      return "editor";
    }
    return "";
  }

  setRole($role: string, element: any) {
    this.apiService.assignRole($role, element.uid);
  }

  protected readonly UserService = UserService;

  async validate(user: any) {
    await this.apiService.validateSubscription(user);
    await this.loadUsers();
  }

  async resendVerificationEmail(user: any) {
    const res = await this.apiService.resendVerificationEmail(user);
    this.snack.open(res?.msg ?? "Error resending verification",undefined,{panelClass: (res?.success ? "ok" : "snack-error"), duration: 3000});
  }
}
