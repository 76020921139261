import {Component, OnInit} from '@angular/core';
import {UserActivityComponent} from "../../../components/user-activity/user-activity.component";
import {HeroBgService} from "../../../services/hero-bg.service";

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [
    UserActivityComponent
  ],
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.scss'
})
export class ActivityComponent implements OnInit {
  constructor(
    private hero: HeroBgService) {
  }
  ngOnInit() {
    this.hero.setBg("/assets/img/fast.jpg", "center center");
  }
}
