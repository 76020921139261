import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatAnchor, MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {NgOptimizedImage} from "@angular/common";
import {StatbitsComponent} from "../../../components/statbits/statbits.component";
import {HeroBgService} from "../../../services/hero-bg.service";

@Component({
  selector: 'app-ctf',
  standalone: true,
  imports: [
    MatButton,
    RouterLink,
    NgOptimizedImage,
    StatbitsComponent,
    MatAnchor
  ],
  templateUrl: './ctf.component.html',
  styleUrl: './ctf.component.scss'
})
export class CtfComponent implements OnInit, OnDestroy {
  constructor(private hero: HeroBgService) {

  }

  ngOnInit() {
    this.hero.setBg('/assets/img/shipping.jpg');
  }
  ngOnDestroy() {
    this.hero.reset();
  }
}
