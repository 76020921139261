import {Component, Input} from '@angular/core';
import {CurrencyPipe, NgIf} from "@angular/common";
import {LocaleService} from "../../services/locale.service";
import {RouterLink} from "@angular/router";
import {CommerceService, Product} from "../../services/commerce.service";

@Component({
  selector: 'app-price-compact',
  standalone: true,
  imports: [
    CurrencyPipe,
    RouterLink,
    NgIf
  ],
  templateUrl: './price-compact.component.html',
  styleUrl: './price-compact.component.scss'
})
export class PriceCompactComponent {
  @Input() service!: string;
  constructor(protected localeService: LocaleService, private commerceService: CommerceService) {
  }
  get product(): Product | undefined {
    return this.commerceService.products?.find(prod => prod.metadata['service'] === this.service);
  }
  get annotation(): string {
    if (this.service.startsWith('tcspro')) {
      return 'All of this from';
    } else if (this.service.startsWith('cyc') && this.localeService.locale == 'en-GB') {
      return 'From';
    }
    return '';
  }
}
