<section class="header">
  <h1>Buy additional credits</h1>
  <h2>How Top-Ups Work</h2>
  <ul class="productPreamble prodCard">
    <li>1 UK Check = 1 Credit, i.e. €2.75.</li>
    <li>1 IRE Check = 2 Credits, i.e. €5.50.</li>
    <li>1 credit costs just €2.75</li>
  </ul>
  <ul class="productPreamble">
    <li>Still making every search the best priced search anywhere.</li>
    <li>We are here to help you not only Survive, but Thrive.</li>
  </ul>
</section>
<section class="chooseProduct">

  <div class="registerForm">
    <mat-slider [min]="10" [max]="200" [step]="5">
      <input [value]="10" matSliderThumb [formControl]="slideController">
    </mat-slider>
    <div class="credits">{{slideController.value}} credits</div>
    <div class="actions">
      <div class="middle trustedsite-trustmark" data-type="212" data-width="120" data-height="50"></div>
      <button class="next" mat-raised-button color="primary" [disabled]="!total || paymentInitiated"
              (click)="pay()">Pay {{ total / 100 | currency: String(creditProduct?.currency).toUpperCase(): 'symbol':'1.2-2': 'en' }}
      </button>
    </div>
  </div>

</section>
