import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {BugService} from "../../../services/bug.service";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-bug',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    NgIf,
    MatLabel
  ],
  templateUrl: './bug.component.html',
  styleUrl: './bug.component.scss'
})
export class BugComponent {
  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { service: string, bugs: BugService}) {
    this.form = new FormGroup({
      'issue': new FormControl(''),
      'details': new FormControl('')
    });
  }

  placeholder(issue: string): string {
    switch (issue) {
      case 'incorrect':
        return 'Please provide as much information as possible as to why you believe the information provided to be incorrect. If possible please provide screen grabs for further context.';
      case 'missing':
        return 'Please provide as much information as possible as to why you believe the information is missing. If possible please provide screen grabs for further context.';
      default:
        return 'Please provide as much information as possible as to what is wrong with this report. If possible please provide screen grabs for further context.';
    }
  }
}
