<div class="quote-container">
  <p class="disclaimer">
    This quote is subject to verification of all details provided. If adjustments need to be made,
    you will receive an amended quote, reflecting the most accurate estimate we can provide.
  </p>
  <p class="note">
    Currency conversion rate GBP/EUR
    {{ data.currencyRate | number: "1.2-2" : "en" }}
  </p>
  <div class="total-table">
    <div class="table">
      <div class="table-segment">
        @for (key of objectKeys(quoteTableSchema); track key) {
          <div class="row">
            <div class="property">{{ quoteTableSchema[key] }}</div>
            <div class="value">&euro; {{ data?.[key] | number: "1.0-0" : "en" }}</div>
          </div>
        }
      </div>
    </div>
  </div>
  <mat-dialog-actions class="dialog-actions" align="center">
    <a mat-raised-button color="primary" (click)="submitQuote()">Submit Request</a>
    <a mat-raised-button (click)="dialog.closeAll()">Cancel</a>
  </mat-dialog-actions>
</div>
