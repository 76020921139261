import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import {FeedbackService} from "../../../services/feedback.service";
import {CommerceService} from "../../../services/commerce.service";

@Component({
  selector: "app-feedback",
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatIcon],
  templateUrl: "./feedback.component.html",
  styleUrl: "./feedback.component.scss",
})
export class FeedbackComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: { service: string, vrm: string },
    public feedbackService: FeedbackService
  ) {
  }

  protected readonly CommerceService = CommerceService;
}
