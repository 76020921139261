import { DecimalPipe, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatAnchor } from "@angular/material/button";
import {
  MatDialogContent,
  MatDialogActions,
  MatDialogTitle,
  MatDialog,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "../../../services/api.service";
import { quoteTableSchema } from "./ccq.model.consts";

@Component({
  selector: "app-quote",
  standalone: true,
  imports: [DecimalPipe, NgIf, MatDialogContent, MatAnchor, MatDialogActions, MatDialogTitle],
  templateUrl: "./quote.component.html",
  styleUrl: "./quote.component.scss",
})
export class QuoteComponent {
  @Input() data: any;
  totalBreakdown: any;
  quoteTableSchema = quoteTableSchema;

  constructor(
    protected dialog: MatDialog,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {}

  protected readonly Number = Number;

  ngOnInit() {
    this.data.serviceFee = this.data.total * 0.02;
  }

  // convenient method used to retrieve a passed object's keys.
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  async submitQuote() {
    await this.apiService.submitQuote(this.data);
    this.snackBar.open("Thank you for submititing your request!", undefined, {
      panelClass: "snack-ok",
      duration: 5000,
    });
    this.dialog.closeAll();
  }
}
