import {Component, Input} from '@angular/core';
import {BodyTypePipe} from "../../../pipes/body-type.pipe";
import {CurrencyPipe, DecimalPipe, NgTemplateOutlet, PercentPipe} from "@angular/common";
import {EngineTypePipe} from "../../../pipes/engine-type.pipe";
import {TransmissionTypePipe} from "../../../pipes/transmission-type.pipe";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-vrt-data',
  standalone: true,
  imports: [
    BodyTypePipe,
    CurrencyPipe,
    DecimalPipe,
    EngineTypePipe,
    NgTemplateOutlet,
    PercentPipe,
    TransmissionTypePipe,
    MatTooltip
  ],
  templateUrl: './vrt-data.component.html',
  styleUrl: './vrt-data.component.scss'
})
export class VrtDataComponent {
  @Input('data') data: any;
}
