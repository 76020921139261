import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";
import {RouterLink} from "@angular/router";
import {EquivCarsDialog} from "../equiv-cars/equiv-cars";
import {VehicleDataComponent} from "../../tables/vehicle-data/vehicle-data.component";
import {VrtDataComponent} from "../../tables/vrt-data/vrt-data.component";
import {ApiService} from "../../../services/api.service";
import {UserService} from "../../../services/user.service";
import { FcfService } from "../../../services/fcf.service";
import {MatAccordion, MatExpansionModule} from "@angular/material/expansion";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: "dialog-car-details",
  templateUrl: "../dialog-car-details/dialog-car-details.html",
  styleUrl: "../dialog-car-details/dialog-car-details.scss",
  standalone: true,
  imports: [
    MatDialogContent,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    RouterLink,
    VehicleDataComponent,
    VrtDataComponent,
    MatAccordion,
    MatExpansionModule,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem
  ],
})
export class CarDetailsDialog {
  protected readonly Number = Number;

  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private apiService: ApiService,
    protected dialog: MatDialog,
    protected userService: UserService
  ) {}

  async showEquivs() {
      this.dialog.open(EquivCarsDialog, {
        data: {
          data: this.data
        },
      });
  }

  async maskEntry(data: any) {
    await this.apiService.maskEntry(data.id);
    data.masked = data.masked ? null : "masked";
    this.dialog.closeAll();
  }

  protected readonly FcfService = FcfService;
}
