import {Component, Input, OnInit, } from "@angular/core";
import {CurrencyPipe, DatePipe, DecimalPipe, NgClass,} from "@angular/common";
import {MatOption} from "@angular/material/autocomplete";
import {MatAnchor, MatButton} from "@angular/material/button";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatPrefix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatSelect} from "@angular/material/select";
import {AccessDialogComponent} from "../dialogs/access-dialog/access-dialog.component";
import {
  SubscriptionRequiredDialogComponent
} from "../dialogs/subscription-required-dialog/subscription-required-dialog.component";
import {CurrencyService} from "../../services/currency.service";
import {UserService} from "../../services/user.service";
import {MatDialog} from "@angular/material/dialog";
import {MatDatepickerInputEvent, MatDatepickerModule} from "@angular/material/datepicker";
import { provideNativeDateAdapter} from "@angular/material/core";

@Component({
  selector: "app-ffl-content",
  standalone: true,
  imports: [
    MatOption,
    ReactiveFormsModule,
    NgClass,
    MatFormField,
    MatPrefix,
    MatInput,
    MatSelect,
    MatButton,
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    MatDatepickerModule,
    MatAnchor,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: "./ffl-content.component.html",
  styleUrl: "./ffl-content.component.scss",
})
export class FflContentComponent implements OnInit {
  @Input() currency: string | undefined;
  @Input() avgPrice: number | undefined;
  @Input() avgCars: number | undefined;
  datePicker = new FormControl(new Date());
  minDate = new Date(2024, 0, 1);
  maxDate = new Date();
  constructor(
    protected currencyService: CurrencyService,
    protected userService: UserService,
    private dialog: MatDialog
  ) {
    this.fflForm = new FormGroup({
      currency: new FormControl("", [Validators.required]),
      avgPrice: new FormControl("", [Validators.required]),
      avgCars: new FormControl("", [Validators.required]),
    });
  }

  protected readonly Number = Number;
  fflForm: FormGroup;
  ngOnInit() {
    if (this.currency) {
      this.fflForm.get("currency")?.setValue(this.currency);
    }
    if (this.avgPrice) {
      this.fflForm.get("avgPrice")?.setValue(this.avgPrice);
    }
    if (this.avgCars) {
      this.fflForm.get("avgCars")?.setValue(this.avgCars);
    }
  }

  protected providerCost(provider: string, amount: number, transactions: number = 1): number {
    let fee = 0;
    let comission;
    switch (provider) {
      case "Wise":
        comission = FflContentComponent.checkInterval(amount);
        break;
      case "TransferMate":
        comission = 1.004;
        fee = transactions; // They charge 1 GBP/EUR per transaction
        break;
      case "Bank of Ireland":
        comission = 1.03;
        break;
      default:
        comission = 1.025;
        break;
    }
    return amount * comission;
  }

  /**
   * Determines the interval factor based on the given value.
   *
   * @param {number} value - The input value to check against defined intervals.
   * @return {number | null} - The interval factor if a matching interval is found, otherwise null.
   */
  static checkInterval(value: number): number {
    if (value >= 1000 && value <= 24000) return 1.0048;
    if (value >= 25000 && value <= 27000) return 1.0047;
    if (value >= 28000 && value <= 31000) return 1.0046;
    if (value >= 32000 && value <= 35000) return 1.0045;
    if (value >= 36000 && value <= 42000) return 1.0044;
    if (value >= 43000 && value <= 51000) return 1.0043;
    if (value >= 52000 && value <= 65000) return 1.0042;
    if (value >= 66000 && value <= 90000) return 1.0041;

    return 1.0040; // Default value
  }

  goto(dest: string) {
    if (!this.userService.isLoggedIn()) {
      this.dialog.open(AccessDialogComponent, {
        data: { feature: "Forex For Less" },
        backdropClass: "backdrop-blur",
      });
      return;
    } else if (!this.userService.hasAccess("ffl")) {
      this.dialog.open(SubscriptionRequiredDialogComponent, {
        data: { feature: "Forex For Less" },
      });
      return;
    }
    let link;
    switch (dest) {
      case "corporate":
        link = "https://onboarding.paydirect.io/tcs/forms/corporate";
        break;
      case "individual":
        link = "https://onboarding.paydirect.io/tcs/forms/individual";
        break;
      default:
        throw new Error(`${dest} is an invalid destination`);
    }
    window.open(link, "_blank", "noopener");
  }

  async dateChange($event: MatDatepickerInputEvent<Date>) {
    // We need to refresh the rates with the new date
    const newDate = $event.value;
    if (newDate) {
      await this.currencyService.getRates(newDate);
    }
  }
  get avgVolume(): number {
    return Number(this.fflForm.get('avgPrice')?.value) * Number(this.fflForm.get('avgCars')?.value);
  }
}
