import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  /**
   * Undefined on init
   * Set to null or string once loaded
   * @private
   */
  private _locale: string | undefined;
  private static services: { [key: string]: string[]; } = {
    'en-GB': ['cyc','ffl'],
    'en-IE': ['ccq','cyc','fcf','ffl','tcspro','ctf'],
  }
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, private router: Router) {
    this.init();
  }

  init() {
    this._locale = this.getStoredLocale();
    if (!this._locale) {
      this.showOverlay();
    }
  }
  public currency(): string {
    return this._locale == 'en-IE' ? '€' : '£';
  }
  public showService(service: string): boolean {
    if (this._locale) {
      return LocaleService.services[this._locale].indexOf(service) > -1;
    }
    return false;
  }
  public get locale() {
    return this._locale;
  }

  /**
   * Sets the locale to either en-GB or en-IE based on the user's browser language.
   * Default is en-GB
   *
   * @returns {string} The locale string determined by the user's browser language.
   *                   Returns 'en-GB' if browser language is not available or not supported.
   */
  // private setLocaleBasedOnBrowser(): 'en-GB' | 'en-IE' | undefined {
  //   const userLanguage = window.navigator.languages ? window.navigator.languages[0] : window.navigator.language;
  //
  //   // Check for Irish or British English locales
  //   switch (userLanguage.toLowerCase()) {
  //     case 'en-ie':  // Irish English
  //     case 'ga-ie':  // Irish
  //       return 'en-IE';
  //     case 'en-gb':  // British English
  //     case 'en-uk':  // Sometimes 'en-UK' is used
  //     case 'en':     // Generic English, assume British by default
  //       return 'en-GB';
  //     default:
  //       return undefined;
  //   }

  /**
   * Set the locale for the application.
   * Passing a null parameter resets the locale selection
   * Passing undefined does nothing
   * @param {string} locale - The locale to set.
   * @return {void}
   */
  public setLocale(locale: 'en-GB' | 'en-IE' | null | undefined): void {
    if (locale === undefined) {
      return;
    }
    if (locale === null) {
      this._locale = undefined;
      this.storage.remove('locale');
      this.showOverlay();
    } else {
      this._locale = locale;
      this.setStoredLocale(locale);
      this.dismissOverlay();
    }
    // console.log('Locale is ', this._locale);
  }

  // }
  /**
   * Retrieves the stored locale from the storage.
   *
   * @return {string} - The stored locale.
   */
  private getStoredLocale(): 'en-GB' | 'en-IE' | undefined {
    const locale = this.storage.get('locale');
    if (['en-GB', 'en-IE'].indexOf(locale) > -1) {
      return locale;
    }
    return undefined;
  }

  /**
   * Sets the stored locale in the storage.
   *
   * @param {string} locale - The locale to be stored.
   * @return {void}
   */
  private setStoredLocale(locale: string): void {
    this.storage.set('locale', locale);
  }

  private showOverlay() {
    this.router.navigate([{outlets: {overlay: 'locale'}}])
      .catch(error => console.error(error));
  }

  private dismissOverlay() {
    this.router.navigate([{outlets: {overlay: null}}])
      .catch(error => console.error(error));
  }
  public companyName(): string {
    switch (this._locale) {
      case 'en-GB':
        return "TCS Digital Services t/a TheCarSite. TCS Digital Services Ltd is a private limited company in England, Wales and Northern Ireland whose registered company number is NI692713 and who registered office is 15 Church Street, Cathedral Quarter, Belfast, Northern Ireland, BT1 1PG";
      case 'en-IE':
        return "The Car Site Limited is a private limited company in the Republic of Ireland, whose registered company number is 764936 and who registered office is Coliemore House, Coliemore Road, Dalkey, Dublin, Ireland";
    }
    return '';
  }
}
