<h2 mat-dialog-title>Change Your Password</h2>
<mat-dialog-content>
  <p>Please enter a valid password, consisting of minimum 5 characters.</p>
  <p>You also need to provide your current password to authenticate this request.</p>
  <form [formGroup]="pwdForm">
    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input matInput formControlName="newPassword" type="password" autocomplete="new-password">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Current Password</mat-label>
      <input matInput formControlName="currentPassword" type="password" autocomplete="current-password">
    </mat-form-field>
    <mat-error>{{fbError}}</mat-error>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="pwdForm.invalid || pwdForm.untouched">Save</button>
</mat-dialog-actions>
