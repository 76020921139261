<section class="header">
  <h1><span>One Partner. Every Solution.</span><span>One Fair Fee. All in One Place.</span></h1>
  <h2>We are TheCarSite for You.</h2>
</section>
<section class="pageContent productDetails">
  <app-prod-details [showPriceTag]="false" [compact]="true"></app-prod-details>
</section>
<section class="stats">
  <h2>Why You Need TheCarSite</h2>
  <app-statbits></app-statbits>
  <a color="primary" mat-raised-button routerLink="/contact" >Get Started</a>
</section>

