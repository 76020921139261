import { Injectable } from '@angular/core';
import {QuoteDetailsDialog} from "../components/dialogs/quote-details-dialog/quote-details-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "./api.service";
export interface Quote {
  id: string;
  ts: string;
  vehicle_id: string;
  statCode: string;
  mileage: string;
  priceGBP: string;
  priceEUR: string;
  currencyRate: string;
  total: string;
  nox: string;
  customs_cost: string;
  customs_rate: string;
  vat: string;
  vrtEuro: string;
  transport_cost: string;
  transport_distance: string;
  transport_origin: string;
  transport_destination: string;
  make: string;
  model: string;
  version: string;
  status: string;
  user: string;
}
@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  constructor(private dialog: MatDialog, private apiService: ApiService) { }
  openDialog(element: Quote) {
    this.dialog.open(QuoteDetailsDialog,{
      data: element
    });
  }
  async setQuoteStatus(value: string, element: Quote) {
    await this.apiService.setQuoteStatus(element.id, value);
  }
}
