import {Component, Input} from '@angular/core';
import {CurrencyPipe} from "@angular/common";

@Component({
  selector: 'app-ccq-totals',
  standalone: true,
    imports: [
        CurrencyPipe
    ],
  templateUrl: './ccq-totals.component.html',
  styleUrl: './ccq-totals.component.scss'
})


export class CcqTotalsComponent {
  @Input() totalBreakdown!: any;
  totalSchema: { [key: string]: string } = {
    priceEur: "Vehicle Purchase Price",
    vrtEuro: "VRT",
    customsDetails: "Customs Duty",
    vat: "VAT",
    transportDetails: "Transport",
    total: "Total",
  };
  protected readonly Object = Object;
  protected readonly Number = Number;
}
