import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class HeroBgService {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  public setBg(img: string, position: string = "center center", color: string = "rgba(0,0,0,0.5)", size: string = "cover", transform: string = "", filter: string = "") {

    if (this.document) {
      const bg = this.document.getElementById("heroBg") as HTMLDivElement;
      bg.style.backgroundImage = `url(${img})`;
      bg.style.backgroundPosition = position;
      bg.style.backgroundColor = color;
      bg.style.backgroundSize = size;
      bg.style.transform = transform;
      bg.style.filter = filter;
    }
  }


  public  reset() {
    if (this.document) {
      const bg = this.document.getElementById("heroBg") as HTMLDivElement;
      bg.style.backgroundImage = "";
      bg.style.backgroundColor = "black";
      bg.style.backgroundSize = "";
      bg.style.transform = "";
      bg.style.filter = "";
    }
  }
}
