export interface VehicleDetails {
  loc: "uk" | "ie";
  missingVariables: any;
  options: any;
  id: string;
  euClassification: string;
  firstregisteredInJapan: string | null;
  make: string;
  model: string | null;
  transmissionType: string | null;
  numberOfDoors: string | null;
  engineType: string;
  bodyType: string | null;
  engineCapacity: string;
  mileage: string;
  yearOfManufacture: string;
  yearOfRegistration: string;
  registrationNumber: string;
  registrationNumber_ie: string;
  monthOfRegistration: string;
  nox: string;
  taxStatus: string;
  taxDueDate: string;
  motStatus: string;
  motExpiryDate: string;
  dateOfLastV5CIssued: string;
  markedForExport: string;
  statCode: string;
  autotrader_id: string | null;
  wltpco2: string | null;
  priceGBP: string;
  model_tfl: string;
}

export interface VrtDetails {
  maxNox?: boolean;
  source: 'ros' | 'tcs' | 'tcs-prestige' | 'tcs-estimated-omsp';
  statCode: string;
  wltpco2: string;
  nox: string;
  euClassification: string;
  transmissionType: string;
  engineType: string;
  engineCapacity: string;
  bodyType: string;
  numberOfDoors: string;
  mileage: string;
  firstRegisteredInJapan: boolean;
  make: string;
  model: string;
  version: string;
  unmodifiedVRTAmount: string;
  vrtEuro: string;
  omsp: string;
  monthlyAdjustment: string;
  deprCode: string;
  mileageRed: string;
  deprRate: string;
  omsp_current: string | number;
  co2Charges: string;
  noxCharge: string;
  missingVariables?: Array<keyof VehicleDetails>; // Missing variables to collect
  similar?: any[]; // Similar matches, but not exact
  options?: string[];
  yearOfRegistration?: string;
  monthOfRegistration?: string;
}

export interface CustomsDetails {
  cost: number;
  rate: number;
  comment: string;
}

export interface VatDetails {
  vat: string;
}

export interface VehicleData {
  vehicleDetails?: any;
  vrtDetails?: VrtDetails;
  vatDetails?: VatDetails;
  customsDetails?: CustomsDetails;
  transportDetails?: TransportDetails;
}

export interface TransportDetails {
  cost: string;
  distance: string;
  origin: string;
  destination: string;
  [key: string]: any;
}



export const quoteTableSchema: { [key: string]: string } = {
  priceEur: "Purchase Price",
  vrtEuro: "VRT",
  vat: "VAT",
  customsDetails: "Customs Duty",
  transportDetails: "Transport",
  serviceFee: "Service Fee (2%)",
  total: "Total",
};
