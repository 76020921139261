<table mat-table [dataSource]="notifications" matSort matSortActive="ts" matSortDirection="desc">
  <ng-container matColumnDef="ts">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created/updated</th>
    <td mat-cell *matCellDef="let element"> {{element.ts | date: 'medium'}}</td>
  </ng-container>
  <ng-container matColumnDef="notifications">
    <th mat-header-cell *matHeaderCellDef>Filters</th>
    <td mat-cell *matCellDef="let element" class="filters">{{element | formatNotifications}}</td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button (click)="edit(element)"><mat-icon>edit</mat-icon>Edit</button>
      <button mat-raised-button (click)="delete(element)"><mat-icon>delete</mat-icon>Delete</button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
</table>
<mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="!loaded"></mat-progress-spinner>
