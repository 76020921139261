import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatAnchor, MatButton} from "@angular/material/button";
import {NgOptimizedImage} from "@angular/common";
import {PricetagComponent} from "../../../components/pricetag/pricetag.component";
import {ProductsComponent} from "../../../components/products/products.component";
import {ReviewsComponent} from "../../../components/reviews/reviews.component";
import {RouterLink} from "@angular/router";
import {StatbitsComponent} from "../../../components/statbits/statbits.component";
import {VrmSearchComponent} from "../../../components/vrm-search/vrm-search.component";
import {HeroBgService} from "../../../services/hero-bg.service";
import {ProdDetailsComponent} from "../../../components/prod-details/prod-details.component";

@Component({
  selector: 'app-fcf',
  standalone: true,
  imports: [
    MatButton,
    NgOptimizedImage,
    PricetagComponent,
    ProductsComponent,
    ReviewsComponent,
    RouterLink,
    StatbitsComponent,
    VrmSearchComponent,
    ProdDetailsComponent,
    MatAnchor
  ],
  templateUrl: './fcf.component.html',
  styleUrl: './fcf.component.scss'
})
export class FcfComponent implements OnInit, OnDestroy {
  constructor(private hero: HeroBgService) {}

  ngOnInit() {
    this.hero.setBg('/assets/img/fast.jpg', 'center center');
  }
  ngOnDestroy() {
    this.hero.reset();
  }

}
