<table mat-table [dataSource]="activity" matSort matSortActive="ts" matSortDirection="desc">
  <ng-container matColumnDef="ts">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
    <td mat-cell *matCellDef="let element"> {{element.ts | date: (mobile ? 'shortDate' : 'mediumDate')}}</td>
  </ng-container>
  <ng-container matColumnDef="vrm">
    <th mat-header-cell *matHeaderCellDef>VRM</th>
    <td mat-cell *matCellDef="let element"> {{element.vrm ?? 'n/a'}}</td>
  </ng-container>
  <ng-container matColumnDef="endpoint">
    <th mat-header-cell *matHeaderCellDef>Activity</th>
    <td mat-cell *matCellDef="let element"> {{getActivity(element) }}</td>
  </ng-container>
  <ng-container matColumnDef="make">
    <th mat-header-cell *matHeaderCellDef>Make and Model</th>
    <td mat-cell *matCellDef="let element">
      <strong>{{element.make}}</strong> {{ element.model ?? element.model_tfl }}
    </td>
  </ng-container>
  <ng-container matColumnDef="model">
    <th mat-header-cell *matHeaderCellDef>Model</th>
    <td mat-cell *matCellDef="let element">
      {{ element.model ?? element.model_tfl }}
    </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Report Type</th>
    <td mat-cell *matCellDef="let element">
      {{ CommerceService.prodName(element.endpoint) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="credits">
    <th mat-header-cell *matHeaderCellDef>Credits</th>
    <td mat-cell *matCellDef="let element"> {{element.credits}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="action(element)" class="clickable"></tr>
</table>
<mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>
