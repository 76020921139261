import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true, name: 'formatKey'
})
export class formatKey implements PipeTransform {
  transform(key: string): string {
    switch (key) {
      case 'model':
        key = 'Model';
        break;
      case 'model_tfl':
        key = 'Model version';
        break;
      case 'euClassification':
        key = 'EU classification';
        break;
      case 'firstregisteredInJapan':
        key = 'First registered in Japan?';
        break;
      case 'transmissionType':
        key = 'Transmission';
        break;
      case 'numberOfDoors':
        key = 'Number of doors';
        break;
      case 'engineType':
        key = 'Engine type / fuel';
        break;
      case 'bodyType':
        key = 'Body type';
        break;
      case 'engineCapacity':
        key = 'Engine size';
        break;
      case 'mileage':
        key = 'Mileage (miles)';
        break;
      case 'kms':
        key = 'Mileage (km)';
        break;
      case 'yearOfRegistration':
        key = 'Year of registration';
        break;
      case 'monthOfFirstRegistration':
        key = 'Date of registration';
        break;
      case 'nox':
        key = 'NOx emissions';
        break;
      case 'statCode':
        key = 'Model version';
        break;
    }
    return key;
  }
}
