import {Component, Input} from '@angular/core';
import {BodyTypePipe} from "../../../pipes/body-type.pipe";
import {DatePipe, DecimalPipe, NgIf} from "@angular/common";
import {EngineTypePipe} from "../../../pipes/engine-type.pipe";
import {TransmissionTypePipe} from "../../../pipes/transmission-type.pipe";
import {MilesToKmPipe} from "../../../pipes/miles-to-km.pipe";
import {MatButton} from "@angular/material/button";
import {VinRevealService} from "../../../services/vin-reveal.service";

@Component({
  selector: 'app-vehicle-data',
  standalone: true,
  imports: [
    BodyTypePipe,
    DatePipe,
    DecimalPipe,
    EngineTypePipe,
    TransmissionTypePipe,
    NgIf,
    MilesToKmPipe,
    MatButton
  ],
  templateUrl: './vehicle-data.component.html',
  styleUrl: './vehicle-data.component.scss'
})
export class VehicleDataComponent {
  @Input('data') data: any;

  constructor(protected vinRevealService: VinRevealService) {
  }
}
