import {Component, OnInit} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {Quote, QuotesService} from "../../../../services/quotes.service";
import {ApiService} from "../../../../services/api.service";
import {DecimalPipe, NgIf} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-admin-quotes',
  standalone: true,
  imports: [
    DecimalPipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    MatProgressSpinner,
    NgIf
  ],
  templateUrl: './quotes.component.html',
  styleUrl: './quotes.component.scss'
})
export class AdminQuotesComponent implements OnInit {
  protected loading: boolean | undefined;

  protected quotes = new MatTableDataSource<Quote>([]);

  displayedColumns_quotes = ['id','ts','user','version','total','status'];
  constructor(private apiService: ApiService,protected quoteService: QuotesService) {
  }
  async ngOnInit() {
    this.loading = true;
    await this.loadQuotes();
  }

  private async loadQuotes() {
    this.quotes = new MatTableDataSource<Quote>(await this.apiService.getQuotes())
    this.loading = false;
  }
}
