<section class="tnc header">
  <h1>Privacy and Cookie Policies</h1>
</section>
<section class="tnc page-content">
  <h2>1. Privacy Statement</h2>

  <p >Protecting the privacy and safeguarding the
    personal, commercial and financial information of our Customers and Web site
    visitors is one of our highest priorities. The following Privacy Statement
    explains how we collect and protect your information. By registering with us or
    by using our Web site, you give your consent to the collection and use of
    personal or commercial information by us as explained in this privacy
    statement.</p>

  <p >The following disclosure is made pursuant
    to the European General Data Protection Regulation (GDPR):</p>

  <p >For the purpose of the General Data
    Protection Regulation (GDPR) 2016/679, the data controller is
    {{localeService.companyName()}}
     (“we”, “us”, “our”, “the Company”).</p>

  <p >This privacy statement explains when and
    why we collect personal or commercial information, how we use it, the
    conditions under which we may disclose it to others and how we keep it secure.
    This Privacy Statement applies to the use of our products and to our sales,
    marketing and customer contract fulfilment activities.</p>

  <h2>2. Collection of personal or commercial information</h2>

  <p >We collect information required to provide
    our services, to transact business effectively and to safeguard you and your
    privacy. To do this, we gather information to help us assess your needs and
    preferences.</p>

  <p >The information we collect directly from
    you includes information required to communicate with you, including your name,
    mailing address, telephone number, e- mail address and to identify who you are.
    We also collect demographic information for example when you use our website,
    express your interest with us and/or when you register with us, including,
    birth date, education, occupation, etc. and information about your transactions
    with us. We may also assess your commercial experience and/or your approximate
    annual income to assess your financial position.</p>

  <p >When do we collect personal data about you?</p>

  <p >• When you are using our products.</p>

  <p >• When you interact with us in person,
    through correspondence, by phone, by social media, or through our websites.</p>

  <p >• When we collect personal information from
    other legitimate sources, such as third- party data aggregators, our marketing
    partners, public sources or social networks. We only use this data if you have
    given your consent to them to share your personal data with others.</p>

  <p >The Company does not collect any Special
    Categories of Personal Data about you (including details about any Customers
    race or ethnicity, religious or philosophical beliefs, sexual orientation,
    political opinions, trade union membership, information about your health and
    genetic and biometric data).</p>



  <h2>3. Usage of personal or commercial information</h2>

  <p >We use personal information to provide you with
    quality service and security. This information helps us to improve our
    services, customize your browsing experience and inform you about additional
    products, services or promotions that may be of interest to you.</p>

  <p >We may use your information for the
    following purposes:</p>

  <p >• Send you marketing communications which
    you have requested. These may include information about our products and
    services, events, activities, and promotions of our associated partners’
    products and services. This communication is subscription based and requires
    your consent.</p>

  <p >• Send you information about the products
    and services that you have purchased from us.</p>

  <p >• Perform direct sales activities in cases
    where legitimate and mutual interest is established.</p>

  <p >• Provide you content and information which
    you have requested.</p>

  <p >• Reply to a ‘Contact Us’ or other web
    forms you have completed on one of our Web sites (e.g. to download a
    whitepaper).</p>

  <p >• Follow up on incoming requests (customer
    support, emails, chats, or phone calls).</p>

  <p >• Provide you with access and services
    related to a Free Trial of our applications.</p>

  <p >• Provide access to our Customer Support
    portal or the website.</p>

  <p >• Perform legal and contractual obligations
    such as order confirmation, license details, invoice, reminders, and similar.</p>

  <p >• Notify you about any disruptions to our
    services (system messages).</p>

  <p >• Contact you to conduct surveys about your
    opinion on our products and services.</p>



  <h3>Our affiliates and partners</h3>

  <p >We may share and receive information with
    affiliates and partners if the information is required to provide the product
    or service you have opted in for or requested or to provide you with the
    opportunity to participate in the products or services we or our affiliates
    offer. We may also forge partnerships and alliances, which may include joint
    marketing agreements, with other companies who offer high-quality products and
    services that might be of value to our Customers or theirs. In order to ensure
    that these products and services meet your needs and are delivered in a manner
    that is useful and relevant, we may share some information with partners,
    affiliates and alliances. This allows us and them to better understand the
    offers that are most relevant and useful. The use of your personal information
    is limited to the purposes identified in our relationship with the partner or
    affiliate. We will enter into Data Protection Agreements with all third parties
    for the protection of your personal data.</p>



  <h3>Non-affiliated third parties</h3>

  <p >We do not sell, license, lease or otherwise
    disclose your personal information to any third party for any reason, except as
    described below.</p>

  <p >We reserve the right to disclose your
    personal information to third parties where required by law to regulatory, law
    enforcement or other government authorities or when necessary to protect our
    rights or property.</p>

  <p >To help us improve our services to you, we
    may engage another business to help us to carry out certain internal functions
    such as account processing, order fulfilment, customer service, customer
    satisfaction surveys or other support services or data collection activities
    relevant to our business. Information we collect from you may also be
    transferred to, and stored at, a destination outside the European Economic Area
    (“EEA”) including the United States. It may also be processed by staff
    operating outside the EEA who work for us or for one of our suppliers. We may
    also provide a party with Customer information from our database to help us to
    analyse and identify Customer needs and notify Customer of product and service
    offerings. Use of the information shared is strictly limited to the performance
    of the task we request and for no other purpose. All third parties with whom we
    share personal information are required to protect personal information in a
    manner similar to the way we protect personal information.</p>



  <h2>4. Security</h2>

  <p >All information you provide to us is stored
    on secure servers. Any payment transactions will be encrypted using SSL
    technology. Where we have given you (or where you have chosen) a password which
    enables you to access certain parts of our Web site, you are responsible for
    keeping this password confidential. We ask you not to share a password with
    anyone.</p>

  <p >Unfortunately, the transmission of
    information via the Internet is not completely secure. Although we will do our
    best to protect your personal data, we cannot guarantee the security of your
    data transmitted to our Web site; any transmission is at your own risk. Once we
    have received your information, we will use strict procedures and security
    features to try to prevent unauthorised access.</p>



  <h2>5. Restriction of responsibility</h2>

  <p >If at any time you choose to purchase a
    product or service offered by another company, any personal information you
    share with that company will no longer be controlled under our Privacy
    Statement. We are not responsible for the privacy policies or the content of
    sites we link to and have no control of the use or protection of information
    provided by you or collected by those sites. Whenever you elect to link to a
    co-branded Web site or to a linked Web site, you may be asked to provide
    registration or other information. Please note that the information you are
    providing is going to a third party and you should familiarize yourself with
    the privacy policy provided by that third party.</p>



  <h2>6. Marketing</h2>

  <p >We may use your data to provide you with
    information about our goods and services which may be of interest to you and we
    or our agents may contact you about these by post and telephone and other
    means.</p>

  <p >If you are an existing customer, we will
    only contact you by electronic means (such as email, phone, voicemail or SMS)
    with information about our goods and services similar to those which were the
    subject of a previous expression of interest or sale to you.</p>

  <p >If you do not want us to use your data in
    this way please tick the relevant boxes when we collect your marketing
    preferences. You may change your preferences at any time by contacting a Customer
    Services representative. An opt out election made by one account owner of a
    joint account is applicable to all account owners of the joint account. An
    opt-out election must be made for each separate account you hold with us.</p>



  <h2>Use of “Cookies”</h2>

  <p >We use cookies to assist us in securing
    your activities and to enhance the performance of our Web site. (Cookies are
    small text files sent from the Web server to your computer.) Cookies used by us
    do not contain any personal information nor do they contain account or password
    information. They merely allow the site to recognize that a page request comes
    from someone who has already logged on.</p>

  <p >We may share Web site usage information
    about visitors to the Web site with reputable advertising companies for
    targeting our Internet banner advertisements on this site and other sites. For
    this purpose, pixel tags (also called clear gifs or web beacons) may be used to
    note the pages you have visited. The information collected by the advertising
    company through the use of these pixel tags is not personally identifiable.</p>

  <p >If you wish to block cookies, you may do so
    through your browser’s settings. You can delete cookies that are already on
    your computer and you can set your browser to prevent them from being placed
    going forward. Please refer to the browser’s help menu for further information.
    However, please bear in mind that disabling cookies may adversely affect your
    user experience on the Service. To learn more about how to reject cookies,
    visit <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a> </p>



  <h2>7. Sale Of Business</h2>

  <p >The Company reserves the right to transfer
    information (including your Personal and/or Commercial Data) to a third party
    in the event of a sale, merger, liquidation, receivership or transfer of all or
    substantially all of the assets of our company provided that the third party
    agrees to adhere to the terms of the Privacy Policy and provided that the third
    party only uses your Personal Data for the purposes that you provided it to us.
    You will be notified in the event of any such transfer and you will be afforded
    an opportunity to opt-out.</p>



  <h2>8. Your Rights</h2>

  <p >You have the right to request a copy of the
    information held by us about you. You also have the right to access your data,
    have changed any inaccuracies in the details we hold about you, the right to
    object to the use of your data and the right to block any specific uses of your
    data, by means of a request in writing to {{localeService.companyName()}}.</p>



  <h2>9. Our legal basis for collecting personal or commercial
    data</h2>



  <h3>Collecting personal or commercial data based on consents</h3>

  <p >The collection of personal or commercial
    data based on consent from the data subject will be done by using “Consent
    Forms” that will store documentation related to the consent given by the
    individual. Individual consents will always be stored and documented in our
    systems.</p>



  <h3>Collecting personal or commercial data based on contracts</h3>

  <p >We use personal or commercial information
    for fulfilling our obligations related to contracts and agreements with
    customers, partners and suppliers.</p>



  <h3>Collecting personal or commercial data based on legitimate
    interest</h3>

  <p >We may use personal or commercial data if
    it is considered to be of legitimate interest, and if the privacy interests of
    the data subjects do not override this interest. Normally, to establish the
    legal basis for data collection, an assessment has been made during which a
    mutual interest between us and the individual person has been identified.</p>



  <h3>How long do we keep your personal or commercial data?</h3>

  <p >We store personal or commercial data for as
    long as we find it necessary to fulfil the purpose for which the personal data
    was collected, while also considering our need to answer your queries or
    resolve possible problems, to comply with legal requirements under applicable
    laws, to attend to any legal claims/complaints, and for safeguarding purposes.</p>

  <p >This means that we may retain your personal
    data for a reasonable period of time after your last interaction with us. When
    the personal data that we have collected is no longer required, we will delete
    it in a secure manner. We may process data for statistical purposes, but in
    such cases, data will be anonymized.</p>



  <p >You have the following rights with respect
    to your personal or commercial data:</p>

  <p >• The right to request a copy of your
    personal or commercial data that we hold about you.</p>

  <p >• The right to request that we correct your
    personal or commercial data if inaccurate or out of date.</p>

  <p >• If you are a customer, you may update
    your user profile by sending a request to our support team.</p>

  <p >• The right to request that your personal or
    commercial data is deleted when it is no longer necessary for us to retain such
    data for legal and contractual purposes.</p>

  <p >• The right to withdraw any consent to
    personal or commercial data processing at any time. For example, your consent
    to receive e-marketing communications:</p>

  <p >• If you want to withdraw your consent to
    e-marketing, please make use of the link to manage your subscriptions included
    in our communication. Please note that you may still receive system messages
    and administrative communications from us, such as order confirmations, system
    messages and notifications about your activities.</p>

  <p >• The right to request that we provide you
    with your personal or commercial data and, if possible, to pass on this
    information directly (in a portable format) to another data controller when the
    processing is based on consent or contract.</p>

  <p >• The right to request a restriction on
    further data processing, in case there is a dispute in relation to the accuracy
    or processing of your personal or commercial data.</p>

  <p >• The right to object to the processing of
    personal or commercial data, in case data processing has been based on
    legitimate interest and/or direct marketing.</p>

  <p >• The right to complain to us and/or the
    Data Protection Commission.</p>

  <p >• You can submit your request or complaint
    to following email: <a href="mailto:support@thecarsite.com">{{ 'support@thecarsite.com' }}</a>
  </p>



  <h2>10. Changes to This Privacy Statement</h2>

  <p >From time to time, we may update this
    Privacy Statement. In the event we change this Privacy Statement, the revised
    Privacy Statement will promptly be posted to the Web sites and we will post a
    notice on our Web sites informing you of such changes. You agree to accept
    posting of a revised Privacy Statement electronically on the Web site as actual
    notice to you. Any dispute over our Privacy Statement is subject to this notice
    and our Customer Agreement. We encourage you to periodically check back and review
    this policy so that you always will know what information we collect, how we
    use it, and to whom we disclose it. If you have any questions that this
    statement does not address, please contact a Customer Services representative.</p>



  <h3>11. Jurisdiction</h3>

  <p>This Privacy Policy shall be governed and construed in
    accordance with the laws of England, Wales and Northern Ireland, without regard
    to its conflict of law provisions.</p>

</section>
