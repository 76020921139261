import { Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,  MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {HttpErrorResponse} from "@angular/common/http";
import {UserService} from "../../../services/user.service";
import {CommerceService} from "../../../services/commerce.service";

@Component({
  selector: 'app-upgrade',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogTitle
  ],
  templateUrl: './upgrade.component.html',
  styleUrl: './upgrade.component.scss'
})
export class UpgradeComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { error: HttpErrorResponse},  protected userService: UserService,protected commerceService: CommerceService) {

  }

}
