<div class="localeSelectorContainer">
  <div class="localeSelector prodCard">
    <img class="logo" ngSrc="/assets/thecarsite.webp" width="400" height="96" alt="TheCarSite">
    <h2>Welcome!</h2>
    <h3>Please select your market.</h3>
    <div class="mapSelector">
      <div class="selectorArea ie" (mouseover)="selected = 'ie'" (mouseout)="selected = undefined" (click)="localeService.setLocale('en-IE')"></div>
      <div class="selectorArea uk" (mouseover)="selected = 'uk'" (mouseout)="selected = undefined" (click)="localeService.setLocale('en-GB')"></div>
      <img class="default" priority [ngStyle]="{'display': selected != 'ie' ? 'inline' : 'none' }" ngSrc="/assets/map/ie.png" width="400" height="487" alt="IE">
      <img class="selected" [ngStyle]="{'display': selected === 'ie' ? 'inline' : 'none' }" ngSrc="/assets/map/ie_green.png" width="400" height="487" alt="IE selected">
      <img class="default" priority [ngStyle]="{'display': selected != 'uk' ? 'inline' : 'none' }" ngSrc="/assets/map/uk.png" width="400" height="487" alt="UK">
      <img class="selected" [ngStyle]="{'display': selected === 'uk' ? 'inline' : 'none' }" ngSrc="/assets/map/uk_green.png" width="400" height="487" alt="UK selected">
    </div>
  </div>
</div>

