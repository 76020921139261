import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {UserService} from "../services/user.service";

export const adminGuard: CanActivateFn = (route, state) => {
  const canActivate= inject(UserService).admin;
  const router = inject(Router);
  if (!canActivate) {
    router.navigate(['/home/search']);
  }
  return canActivate;
};
