import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe, DecimalPipe, NgIf} from "@angular/common";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatAnchor} from "@angular/material/button";
import {ApiService} from "../../../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatTooltip} from "@angular/material/tooltip";
import {MatSort, MatSortHeader, MatSortModule} from "@angular/material/sort";

@Component({
  selector: 'app-dealers',
  standalone: true,
  imports: [
    DecimalPipe,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    DatePipe,
    MatHeaderCellDef,
    MatAnchor,
    MatProgressSpinner,
    NgIf,
    MatTooltip,
    MatSortModule
  ],
  templateUrl: './admin-dealers.component.html',
  styleUrl: './admin-dealers.component.scss'
})
export class AdminDealersComponent implements OnInit{
  protected dealers = new MatTableDataSource<any>([]);
  protected displayedColumns = ['name','address','postcode','phone','nCarsIreland','nCarZone','nDoneDeal','updated'];
  protected loading: boolean = false;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(private apiService: ApiService, private snack: MatSnackBar) {
  }

  async ngOnInit() {
    this.loading = true;
    const res = await this.apiService.getDealers()
      .catch(error => { console.error(error) });
    if (res?.success) {
      this.dealers = new MatTableDataSource<any>(res.data);
      this.dealers.sort = this.sort;
    } else {
      this.snack.open(`Could not load Dealers: ${res?.msg}`,undefined,{panelClass: 'snack-error', duration: 4000});
    }
    this.loading = false;
  }
}
