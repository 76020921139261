import { Component } from '@angular/core';
import {DecimalPipe, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-reviews',
  standalone: true,
  imports: [
    DecimalPipe,
    NgOptimizedImage
  ],
  templateUrl: './reviews.component.html',
  styleUrl: './reviews.component.scss'
})
export class ReviewsComponent {
  reviews = [
     'Their customer service is second to none.' ,
     'The product quality is consistently outstanding, exceeding my expectations every time.' ,
     'I was completely impressed with their professionalism and customer service.' ,
     'This is my go-to place for all things cars - the best!' ,
     'Their staff is not only friendly but also highly skilled.' ,
     'Pricing is fair and transparent - definitely value for money.' ,
     'Ease of use and quality are hallmarks of their services.',
     'Their customer service is second to none.' ,
     'The product quality is consistently outstanding, exceeding my expectations every time.' ,
     'I was completely impressed with their professionalism and customer service.' ,
     'This is my go-to place for all things cars - the best!' ,
     'Their staff is not only friendly but also highly skilled.' ,
     'Pricing is fair and transparent - definitely value for money.' ,
     'Ease of use and quality are hallmarks of their services.',
  ];
  public reviewGroups(groupSize: number = 4): any[] {
    const groups = [];
    let reviewGroup = [];
    for (let i = 0; i < this.reviews.length; i++) {
      if (i % groupSize === 0) {
        if (reviewGroup.length > 0) {
          groups.push(reviewGroup);
        }
        reviewGroup = [];
      }
      reviewGroup.push(this.reviews[i]);
    }
    return groups;
  }
}
