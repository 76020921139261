import {Component, ViewEncapsulation} from '@angular/core';
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: 'app-privacy',
  standalone: true,
  imports: [],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PrivacyComponent {
  constructor(protected localeService: LocaleService) {
  }


}
