import {CanActivateFn, Router} from "@angular/router";
import {UserService} from "../services/user.service";
import {inject} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {
  SubscriptionRequiredDialogComponent
} from "../components/dialogs/subscription-required-dialog/subscription-required-dialog.component";

export const paidGuard: CanActivateFn = (route,state) => {
  const userService = inject(UserService);
  const access = userService.hasAccess(route.data["service"]);
  if (!access) {
    const router = inject(Router);
    router.navigate(["/home"]);
    const dialog = inject(MatDialog);
    dialog.open(SubscriptionRequiredDialogComponent,{
      data: {
        service: route.data["service"]
      }
    });
  }
  return access;
};
