<section class="header">
  <h1>Your Business details</h1>
</section>
<section class="registerForm">
  <div class="registerForm">
    <p>This information will be used to contact you regarding new products that TheCarSite has to offer and to
      provide you with invoices.</p>
    <app-business-details-form #businessDetailsFormComponent></app-business-details-form>

    <div class="actions">
      <div class="middle trustedsite-trustmark" data-type="211" data-width="120" data-height="50"></div>
      <button [disabled]="businessDetailsFormComponent.detailsForm.invalid" class="next" mat-raised-button color="primary" (click)="businessDetailsFormComponent.saveBusinessDetails(); next();">Next</button>
    </div>

  <!--    <div class="stepCounter">Step {{ step }} / 4</div>-->
  <!--    <mat-progress-bar [value]="step * 25" mode="determinate" color="primary"></mat-progress-bar>-->
  </div>
</section>
