<form class="vrmSearch" (submit)="go($event)">
  <div class="blueBg">
    <img ngSrc="/assets/icons/ie.svg" width="16" height="16" alt="Ireland" *ngIf="!flag || flag === 'ie'">
    <img ngSrc="/assets/icons/uk.svg" width="16" height="16" alt="United Kingdom"  *ngIf="!flag || flag === 'uk'">
  </div>

  <input #vrmInput placeholder="Enter reg" type="text" [disabled]="disabled">
  <button type="submit" [disabled]="disabled">Go</button>
  <div class="tradePlate">
    <div class="logoName">TheCarSite</div>
    <img class="icon" ngSrc="/assets/thecarsite-icon.svg" width="16" height="16" alt="TheCarSite">
    <div class="product">{{ CommerceService.prodName(mode)}}</div>
    <div class="product short">{{ mode}}</div>
  </div>
</form>
<div class="errors" *ngIf="error">{{ error }}</div>
