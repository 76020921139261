import {Component, ViewEncapsulation} from '@angular/core';
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TermsComponent {
  constructor(protected localeService: LocaleService) {
  }
}
