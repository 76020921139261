import { Component } from '@angular/core';
import {NavComponent} from "../nav/nav.component";
import {UserService} from "../../services/user.service";
import { RouterLink} from "@angular/router";
import {MatAnchor, MatButton} from "@angular/material/button";
import {NgOptimizedImage} from "@angular/common";
import {NavService} from "../../services/nav.service";
import {NavHamburgerComponent} from "../nav-hamburger/nav-hamburger.component";
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NavComponent,
    RouterLink,
    MatButton,
    NgOptimizedImage,
    NavHamburgerComponent,
    MatAnchor
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(protected userService: UserService, protected nav: NavService, protected localeService: LocaleService) {
  }
  protected flag() {
    if (this.localeService.locale === undefined) {
      return '';
    }
    return `/assets/flags/${this.localeService.locale}.svg`;
  }
}
