import { Component } from '@angular/core';
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {
  constructor( protected localeService: LocaleService) {
  }
}
