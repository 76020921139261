<div class="prodCard">
  <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>
  <table mat-table [dataSource]="quotes">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="ts">
      <th mat-header-cell *matHeaderCellDef>Time</th>
      <td mat-cell *matCellDef="let element">{{ element.ts }}</td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let element">{{ element.user }}</td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef>Vehicle</th>
      <td mat-cell *matCellDef="let element">{{ element.make }} {{ element.model }} {{ element.version }}</td>
    </ng-container>
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let element" class="euro">{{ element.total | number : '1.2-2' : 'en' }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns_quotes"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns_quotes;" class="clickable"
        (click)="quoteService.openDialog(element)"></tr>
  </table>
</div>
