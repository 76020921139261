import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginComponent} from "../../components/login/login.component";
import {RouterLink} from "@angular/router";
import {ProductsComponent} from "../../components/products/products.component";
import {NavComponent} from "../../components/nav/nav.component";
import {MatAnchor, MatButton} from "@angular/material/button";
import {DecimalPipe, NgOptimizedImage} from "@angular/common";
import {VrmSearchComponent} from "../../components/vrm-search/vrm-search.component";
import {HeaderComponent} from "../../components/header/header.component";
import {ReviewsComponent} from "../../components/reviews/reviews.component";
import {StatbitsComponent} from "../../components/statbits/statbits.component";
import {HeroBgService} from "../../services/hero-bg.service";
import {ProdDetailsComponent} from "../../components/prod-details/prod-details.component";
import {AboutComponent} from "../../components/about/about.component";

@Component({
  selector: 'app-frontpage',
  standalone: true,
  imports: [
    LoginComponent,
    ProductsComponent,
    NavComponent,
    MatButton,
    RouterLink,
    DecimalPipe,
    VrmSearchComponent,
    HeaderComponent,
    ReviewsComponent,
    StatbitsComponent,
    NgOptimizedImage,
    ProdDetailsComponent,
    AboutComponent,
    MatAnchor
  ],
  templateUrl: './frontpage.component.html',
  styleUrl: './frontpage.component.scss'
})
export class FrontpageComponent implements OnInit, OnDestroy{
  constructor(private hero: HeroBgService) {}

  ngOnInit() {
    this.hero.setBg('/assets/img/f1.jpg', 'center center', 'rgba(255,255,255,0.5)');
  }
  ngOnDestroy() {
    this.hero.reset();
  }
}
