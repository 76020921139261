import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {DecimalPipe, NgClass, NgForOf, NgStyle} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import { FcfService } from "../../../services/fcf.service";
import {ApiResponse, ApiService} from "../../../services/api.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: "equiv-cars",
  templateUrl: "../equiv-cars/equiv-cars.html",
  styleUrl: "../equiv-cars/equiv-cars.scss",
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, DecimalPipe, NgForOf, MatButtonModule, MatProgressSpinner, MatDialogActions, NgClass, NgStyle],
})
export class EquivCarsDialog implements OnInit{
  protected readonly Number = Number;
  protected loadingEquivs: boolean = false;
  protected equivs: ApiResponse | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {data: any}, private apiService: ApiService, protected dialog: MatDialog) {
  }
  async ngOnInit() {
    this.loadingEquivs = true;
    this.equivs = <ApiResponse>await this.apiService.getEquivs(this.data.data.id).catch(e => console.error(e));
    this.loadingEquivs = false;
  }

  protected readonly FcfService = FcfService;
}
