<h2 mat-dialog-title>{{ data.make }} {{ data.model }}</h2>
<mat-dialog-content>
  <div role="table" class="searchResTable valsRight">
    <ng-container *ngFor="let res of data | formatRes: true | keyvalue">
      <ng-container *ngIf="res.value">
        <div role="rowheader" class="rowheader">{{ res.key }}</div>
        <div role="cell" class="value"> {{ res.value }}</div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="userService.admin">
      <div role="rowheader" class="rowheader">Status</div>
      <div role="cell" class="value">
        <mat-select [value]="data.status" (valueChange)="quoteService.setQuoteStatus($event, data)">
          <mat-option value="PENDING">Pending</mat-option>
          <mat-option value="DECLINED">Declined</mat-option>
          <mat-option value="ACCEPTED">Accepted</mat-option>
          <mat-option value="COMPLETED">Completed</mat-option>
        </mat-select>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-flat-button color="primary" (click)="dialog.closeAll()" mat-raised-button>Close</button>
</mat-dialog-actions>
