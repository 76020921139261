import { Component } from "@angular/core";
import {BusinessDetailsFormComponent} from "../../business-details-form/business-details-form.component";
import {MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";

@Component({
  selector: "app-business-details-dialog",
  standalone: true,
  imports: [
    BusinessDetailsFormComponent,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogTitle,
  ],
  templateUrl: "./business-details-dialog.component.html",
  styleUrl: "./business-details-dialog.component.scss",
})
export class BusinessDetailsDialogComponent {
  constructor(private dialog: MatDialog) {}

  save(saved: boolean) {
    if (saved) {
      // Set user data to user class
      this.dialog.closeAll();
    }
  }
}
