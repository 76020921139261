import {Component, Inject} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {ApiService} from "../../../services/api.service";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";

interface Intent {
  id: string; // SetupIntent id
  next_action: {
    type: string; // Type of the next action to perform, one of redirect_to_url, use_stripe_sdk, alipay_handle_redirect, oxxo_display_details, or verify_with_microdeposits.
    redirect_to_url?: {
      url?: string; // The URL you must redirect your customer to in order to authenticate.
    }
  };
  status: string;
  payment_method: string; // Payment method id
}

@Component({
  selector: 'app-update-payment-dialog',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, NgIf, MatButton, NgOptimizedImage, MatDialogActions],
  templateUrl: './update-payment-dialog.component.html',
  styleUrl: './update-payment-dialog.component.scss'
})
export class UpdatePaymentDialogComponent {

  constructor(private apiService: ApiService, @Inject(LOCAL_STORAGE) private storage: StorageService) {
  }


  async validate() {
    const res = await this.apiService.updatePayment();
    // Save the payment id in localstorage
    this.storage.set("session_id", res.data.id);
    // Redirect to provided link
    window.location = res.data.location;
  }
}
