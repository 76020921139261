import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";

export interface Currency {
  short: string;
  three: string;
  symbol: string;
}

@Injectable({
  providedIn: "root"
})
export class CurrencyService {
  public rates: any = {
    eur: 1, jpy: 161.174, gbp: 0.83, usd: 1.07, cny: 7.7, inr: 89.89, zar: 19.14, cad: 1.46
  };
  public currencies: Currency[] = [{
    short: 'eur', three: 'EUR', symbol: '€'
  }, {
    short: 'gbp', three: 'GBP', symbol: '£'
  }, {
    short: 'cad', three: 'CAD', symbol: '$'
  }, {
    short: 'cny', three: 'CNY', symbol: '¥'
  }, {
    short: 'inr', three: 'INR', symbol: '₹'
  }, {
    short: 'jpy', three: 'JPY', symbol: '¥'
  }, {
    short: 'usd', three: 'USD', symbol: '$'
  }, {
    short: 'zar', three: 'ZAR', symbol: 'R'
  }];

  constructor(private apiService: ApiService) {
    this.getRates()
      .catch(e => console.error(e));
  }
  get eur(): Currency
  {
    return this.currencies[0];
  }
  get gbp(): Currency
  {
    return this.currencies[1];
  }
  public getRate(currency: Currency): number {
    return this.rates[currency.short] ?? 0;
  }
  convert(from: Currency, to: Currency, amount: number): number {
    if (!this.rates[from.short]) {
      return 0;
    }
    return amount * (this.rates[to.short] / this.rates[from.short]);
  }

  public async getRates(date: Date = new Date()) {
    const res = await this.apiService.getCurrencyRates(date)
      .catch(e => console.error(e));
    if (res?.success && res.data["rates"]) {
      this.rates = res.data["rates"];
    }
  }
}
