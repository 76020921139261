import {Component, Inject} from '@angular/core';
import {MatAnchor, MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";

@Component({
  selector: 'app-access-dialog',
  standalone: true,
  imports: [
    MatButton,
    RouterLink,
    MatDialogContent,
    MatDialogActions,
    MatDialogTitle,
    MatAnchor
  ],
  templateUrl: './access-dialog.component.html',
  styleUrl: './access-dialog.component.scss'
})
export class AccessDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) protected data: any, protected dialog: MatDialog) {}
}
