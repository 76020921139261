<section class="header">
  <h1>
    <span>Stop Wasting Time Calculating VRT, VAT,</span>
    <span>Customs, NOx, Forex & Shipping.</span>
  </h1>
  <h2>Stop Wasting Money on Crap Solutions.</h2>
</section>
<section class="hero">
  <app-prod-details singleProduct="ccq" [showPriceTag]="true" [feature]="true"></app-prod-details>
</section>
<section class="usps">
  <h2>Why You Should Use Calculate Cars Quicker</h2>
  <div class="uspsContainer">
    <div class="usp">
      <img
        ngSrc="/assets/icons/piggy-bank.svg"
        height="50"
        width="50"
        alt="Piggy Bank"/>
      <h3>Save Money</h3>
      <div class="usp-content">
        <p>
          Our competitors are charging a small fortune for each calculation which
          when added up is an actual fortune.
        </p>
        <p>With TheCarSite there is one small flat fee.</p>
        <p>It’s Your Money. It’s Your Choice.</p>
      </div>
    </div>
    <div class="usp">
      <img
        ngSrc="/assets/icons/stopwatch.svg"
        height="50"
        width="50"
        alt="Stopwatch"/>
      <h3>Save Time</h3>
      <div class="usp-content">
        <p>
          Dealers typically spend 10 minutes per car calculating VRT, VAT,
          Customs, NOx, Forex and Shipping.
        </p>
        <p>With TheCarSite it takes 30 seconds.</p>
        <p>It’s Your Time. It’s Your Choice.</p>
      </div>
    </div>
    <div class="usp">
      <img
        ngSrc="/assets/icons/aim.svg"
        height="50"
        width="50"
        alt="Bullseye"/>
      <h3>Accuracy</h3>
      <div class="usp-content">
        <p>
          We, like you, got tired of endless scrolling through drop downs then
          only to find the wrong result.
        </p>
        <p>With TheCarSite you will have the correct answer within 30 seconds.</p>
        <p>Precision or Crap. Your Call.</p>
      </div>
    </div>
  </div>
  <a
    class="c2aLarge"
    mat-raised-button
    color="primary"
    routerLink="/register">
    Get Started
  </a>
</section>
<section class="reviews">
  <h2>What Our Customers Just Like You Say</h2>
  <app-reviews></app-reviews>
</section>
<section class="valueProp">
  <h2>What You Get From Calculate Cars Quicker</h2>
  <div class="valueProps">
    <div>
      <img ngSrc="/assets/icons/thirty.svg" width="16" height="16" alt="30s"/>
      <p>All calculations provided in max 30 seconds</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/95.svg" width="16" height="16" alt="95%"/>
      <p>
        95% of calculations achieved with Reg only.
      </p>
    </div>
    <div>
      <div class="img">VRT</div>
      <p>VRT calculations in seconds</p>
    </div>
    <div>
      <div class="img">VAT</div>
      <p>VAT calculations in seconds</p>
    </div>
    <div>
      <div class="img">Customs</div>
      <p>Customs calculations in seconds</p>
    </div>
    <div>
      <div class="img">NOx</div>
      <p>NOx calculations in seconds</p>
    </div>
    <div>
      <img
        ngSrc="/assets/icons/currencies.svg"
        width="16"
        height="16"
        alt="Forex"/>
      <p>Forex calculations in seconds</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/ship.svg" width="16" height="16" alt="Ship"/>
      <p>Shipping calculations in seconds</p>
    </div>
  </div>
  <a class="c2aLarge" mat-raised-button routerLink="/register">
    Get Started
  </a>
</section>
<section class="stats">
  <h2>Why You Need Calculate Cars Quicker</h2>
  <app-statbits sort="ccq"></app-statbits>
  <a
    class="c2aLarge"
    mat-raised-button
    color="primary"
    routerLink="/register">
    Get Started
  </a>
</section>
<section class="hero prods">
  <div class="prods">
    <app-products exclude="ccq"></app-products>
  </div>
</section>
