<h3 mat-dialog-title>
  @if (data.mode === 'fcf') {
    Filter your table
  } @else {
    Choose filters for your notification
  }
</h3>
<mat-dialog-content>
  <div class="filtersContainer" [formGroup]="data.filtersFormGroup">
    <input type="hidden" formControlName="id">
    <mat-form-field>
      <mat-label>Seller Types</mat-label>
      <mat-select formControlName="sellerTypes" multiple>
        <mat-option value="Private">Private</mat-option>

        <mat-option value="Trade-sites">Trade - sites</mat-option>
        <mat-option value="Trade-auctions">Trade - auctions</mat-option>

        <mat-option value="Other">Other</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Body Types</mat-label>
      <mat-select formControlName="bodyTypes" multiple>
        @for (bodyType of fcfService.bodyTypes; track bodyType) {
          <mat-option [value]="bodyType">{{ bodyType | bodyType }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Makes
        <mat-progress-spinner *ngIf="fcfService.loadingFacets" mode="indeterminate"
                              [diameter]="15"></mat-progress-spinner>
      </mat-label>
      <mat-select multiple formControlName="makes" #makesFilter [disabled]="fcfService.loadingFacets">
        @for (make of fcfService.makes | keyvalue; track make.key) {
          <mat-option [value]="make.key">{{ make.key }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="double">
      <mat-label>Models
        <mat-progress-spinner *ngIf="fcfService.loadingFacets" mode="indeterminate"
                              [diameter]="15"></mat-progress-spinner>
      </mat-label>
      <mat-select multiple formControlName="models">
        @for (make of makesFilter.value; track make) {
          @for (model of fcfService.makes[make]; track model) {
            <mat-option [value]="model">{{ model }}</mat-option>
          }
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fuel Types</mat-label>
      <mat-select formControlName="engineTypes" multiple>
        <mat-option [value]="1">Petrol</mat-option>
        <mat-option [value]="2">Diesel</mat-option>
        <mat-option [value]="3">Electric</mat-option>
        <!--      <mat-option [value]="8">Mild Hybrid Petrol</mat-option>-->
        <mat-option [value]="16">Hybrid Petrol</mat-option>
        <!--      <mat-option [value]="10">Mild Hybrid diesel</mat-option>-->
        <mat-option [value]="15">Hybrid Diesel</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Transmission Types</mat-label>
      <mat-select formControlName="transmissionTypes" multiple>
        <mat-option [value]="1">Manual</mat-option>
        <mat-option [value]="2">Automatic</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Doors</mat-label>
      <mat-select formControlName="numberOfDoors" multiple>
        @for (i of [2, 3, 4, 5, 6, 7]; track i) {
          <mat-option [value]="i">{{ i }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Colour</mat-label>
      <mat-select formControlName="colours" multiple>
        @for (colour of fcfService.colours; track colour) {
          <mat-option [value]="colour">{{ colour }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="double">
      <mat-label>Keyword Search</mat-label>
      <input type="text" matInput formControlName="keyword">
    </mat-form-field>
    <div class="formField triple">
      <mat-label>Total cost to import</mat-label>
      <mat-slider min="0" [max]="fcfService.maxMins.maxCost" step="500" discrete [displayWith]="formatPrice">
        <input matSliderStartThumb formControlName="totalCostMin">
        <input matSliderEndThumb formControlName="totalCostMax">
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Estimated Profits</mat-label>
      <mat-slider [min]="fcfService.maxMins.minProfit" [max]="fcfService.maxMins.maxProfit" step="100" discrete
                  [displayWith]="formatPrice">
        <input matSliderStartThumb formControlName="profitMin">
        <input matSliderEndThumb formControlName="profitMax">
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Return on Investment</mat-label>
      <mat-slider min="0" [max]="fcfService.maxMins.maxRoi" step="0.05" discrete [displayWith]="formatPercent">
        <input matSliderStartThumb formControlName="roiMin">
        <input matSliderEndThumb formControlName="roiMax">
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Year</mat-label>
      <mat-slider [min]="fcfService.maxMins.minYear" [max]="fcfService.maxMins.maxYear" step="1" discrete>
        <input matSliderStartThumb formControlName="yearMin">
        <input matSliderEndThumb formControlName="yearMax">
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>CO<sub>2</sub>-Emissions (g/km)</mat-label>
      <mat-slider min="0" [max]="fcfService.maxMins.maxCo2" step="20" discrete [displayWith]="formatGPerKm">
        <input matSliderStartThumb formControlName="co2Min">
        <input matSliderEndThumb formControlName="co2Max">
      </mat-slider>
    </div>

    <div class="formField triple">
      <mat-label>Engine Size</mat-label>
      <mat-slider min="0" [max]="fcfService.maxMins.maxEngineSize" step="200" discrete [displayWith]="formatEngineSize">
        <input matSliderStartThumb formControlName="engineSizeMin">
        <input matSliderEndThumb formControlName="engineSizeMax">
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Mileage</mat-label>
      <mat-slider min="0" [max]="fcfService.maxMins.maxKms" step="500" discrete [displayWith]="formatKms">
        <input matSliderStartThumb formControlName="kmsMin">
        <input matSliderEndThumb formControlName="kmsMax">
      </mat-slider>
    </div>
    <mat-slide-toggle class="triple" formControlName="insuranceWriteOff">Include Cars Written Off by Insurance
      (Categories C/D/S/N)
    </mat-slide-toggle>
    <mat-slide-toggle class="triple" formControlName="showNonMatches">Include Cars Without an Est. Sales Price IRE
    </mat-slide-toggle>
  </div>

  <!--  <div class="formField double" [hidden]="true">-->
  <!--    <mat-label>Purchase price</mat-label>-->
  <!--    <mat-slider min="0" [max]="fcfService.maxPrice" step="500" discrete [displayWith]="formatPrice">-->
  <!--      <input matSliderStartThumb formControlName="purchasePriceMin">-->
  <!--      <input matSliderEndThumb formControlName="purchasePriceMax">-->
  <!--    </mat-slider>-->
  <!--  </div>-->
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Close</button>
  @if (data.mode === 'fcf') {
    <button mat-raised-button (click)="resetFilters()" [disabled]="data.filtersFormGroup.untouched">Reset</button>
    <span class="spacer"></span>
    <button mat-raised-button (click)="createNotification()" [disabled]="data.filtersFormGroup.untouched"
            matTooltip="Receive automated e-mail notification about new vehicles matching the filters you've set.">
      <mat-icon>add_alert</mat-icon>
      Create alert
    </button>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" (click)="applyFilters()" [disabled]="data.filtersFormGroup.untouched">
      Apply
    </button>
  } @else if (data.mode === 'notifications') {
    <span class="spacer"></span>
    <button mat-raised-button color="primary" (click)="createNotification()"
            [disabled]="data.filtersFormGroup.untouched">Save
    </button>
  }
</mat-dialog-actions>
