import { Component} from '@angular/core';
import {NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: 'app-locale-selector',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgStyle,
    NgIf
  ],
  templateUrl: './locale-selector.component.html',
  styleUrl: './locale-selector.component.scss'
})
export class LocaleSelectorComponent {
  selected: string | undefined;
  constructor(protected localeService: LocaleService) {
  }

}
