<section class="header">
  <h1>Cars to Forecourt</h1>
</section>
<section class="hero pageContent">
  <div class="productDetails">
    <div class="ctf prodCard">
      <p>It’s one thing knowing the cost but doing the Admin is a whole different story.</p>
      <p>Cars to Forecourt is a bespoke end to end service.</p>
      <p>TheCarSite handling all aspects of VRT, VAT, Customs, NOx, Shipping and Forex fully for You.</p>
      <p>No more time wasted.</p>
      <p>Register your interest by clicking the button below.</p>
      <a  class="c2aLarge" mat-raised-button color="primary" routerLink="/contact">Register Your Interest</a>
    </div>
  </div>
</section>
<section class="valueProp">
  <h2>What You Get From Cars To Forecourt</h2>
  <div class="valueProps">
    <div>
      <img ngSrc="/assets/icons/customer-service.svg" width="16" height="16" alt="30s">
      <p>Professional End to End Management</p>
    </div>
    <div>
      <div class="img">VRT</div>
      <p>VRT Submission and Payment</p>
    </div>
    <div>
      <div class="img">VAT</div>
      <p>VAT Submission and Payment</p>
    </div>
    <div>
      <div class="img">Customs</div>
      <p>Customs Submission and Payment</p>
    </div>
    <div>
      <div class="img">NOx</div>
      <p>NOx Submission and Payment</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/ship.svg" width="16" height="16" alt="30s">
      <p>Shipping Fully Managed</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/currencies.svg" width="16" height="16" alt="30s">
      <p>The Best Forex Rates</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/car-tag.svg" width="16" height="16" alt="30s">
      <p>Your Car Delivered to Your Forecourt</p>
    </div>

  </div>
  <a  class="c2aLarge" mat-raised-button routerLink="/contact">Register Your Interest</a>
</section>
<section class="stats">
  <h2>Why You Need TheCarSite</h2>
  <app-statbits sort="ctf"></app-statbits>
  <a  class="c2aLarge" mat-raised-button color="primary" routerLink="/register">Get Started</a>
</section>
