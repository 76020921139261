import { Component } from "@angular/core";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatButton} from "@angular/material/button";
import {NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {UserService} from "../../services/user.service";
import {MatIconModule} from "@angular/material/icon";
import {MatDivider} from "@angular/material/divider";
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: "app-nav-hamburger",
  standalone: true,
  imports: [
    MatMenu,
    MatButton,
    MatMenuTrigger,
    MatMenuItem,
    NgIf,
    RouterLink,
    MatIconModule,
    MatDivider,
  ],
  templateUrl: "./nav-hamburger.component.html",
  styleUrl: "./nav-hamburger.component.scss"
})
export class NavHamburgerComponent {
constructor(protected  userService: UserService, protected localeService: LocaleService) {
}
}
