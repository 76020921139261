<div class="productDetails" [ngClass]="{pricetag: showPriceTag, feature: feature, singleProd: product != undefined, compact: compact}">
  @if ((product === undefined || product === 'ccq') && localeService.showService('ccq')) {
<!--  <app-pricetag price="99" prodName="CCQ"></app-pricetag>-->
  <div class="ccq prodCard">
    <h4>Calculate Cars Quicker</h4>
    <p>Calculate every import cost in seconds.</p>
    <p>VRT, VAT, Customs, NOx, Shipping, Forex.</p>
    <app-vrm-search  mode="ccq"></app-vrm-search>
    <app-price-compact [service]="'ccq'"></app-price-compact>
  </div>
  }
  @if ((product === undefined || product === 'cyc') && localeService.showService('cyc')) {
<!--  <app-pricetag price="199" prodName="CYC"></app-pricetag>-->
  <div class="cyc prodCard">
    <h4>Check Your Car</h4>
    <p>Finance, Clocking, Abnormalities, PSVs and Much More.</p>
    <p>Everything you need to know before you buy.</p>
    <app-vrm-search  mode="cyc"></app-vrm-search>
    <app-price-compact [service]="localeService.locale === 'en-IE' ? 'cyc' : 'cyc-uk'"></app-price-compact>
  </div>
  }
  @if ((product === undefined || product === 'ffl') && localeService.showService('ffl')) {
    <div class="ffl prodCard">
      <h4>Forex for less</h4>
      <p>TheCarSite has created an institutional Forex Solution like nothing before it.</p>
      <p>Stop paying retail dressed up as institutional. Your rates are being marked up.</p>
      <p>TheCarSite gives you rates the Commercial Banks would die for, never mind Trade or Retail.</p>
      <a mat-raised-button color="primary" routerLink="/ffl">Learn More</a>
      <app-price-compact [service]="'ffl'"></app-price-compact>
    </div>
  }
  @if ((product === undefined || product === 'fcf') && localeService.showService('fcf')) {
<!--  <app-pricetag price="199" prodName="FCF"></app-pricetag>-->
  <div class="fcf prodCard">
    <h4>Find Cars Faster - Turbocharge your ROI</h4>
    @if (!compact) {
      <p>TheCarSite helps you find your ideal next purchase.</p>
      <p>Search based on your criteria, or simply browse all cars using dealer specific functionality.</p>
      <h5>How to use find cars faster</h5>
    }
    <div class="twoOptions">
      <div class="search prodCard">
        <h4>Search</h4>
        <img ngSrc="/assets/icons/zoom.svg" width="25" height="25" alt="Search" [hidden]="compact">
        <p>Filter by cost of car, exp. profit, ROI, time to sale or all the usuals e.g. make, model etc.</p>
      </div>
      <button class="search" mat-raised-button (click)="gotoFCF()" color="primary">Search now</button>
      <div class="or">or</div>
      <div class="browse prodCard">
        <h4>Browse</h4>
        <img ngSrc="/assets/icons/table.svg" width="25" height="25" alt="Browse" [hidden]="compact">
        <p>Organise the Find Cars Faster listed vehicles by any of the columns available.</p>
      </div>
      <button class="browse" mat-raised-button (click)="gotoFCF()" color="primary">Browse now</button>
    </div>
    <app-price-compact [service]="'fcf'"></app-price-compact>
  </div>
  }

</div>
