import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {MatAnchor, MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {LocaleService} from "../../services/locale.service";

interface Prod { acronym: string; link: string; icon: string; description: string; title: string, available: string[] }

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    NgOptimizedImage,
    MatButton,
    RouterLink,
    MatAnchor
  ],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent {
  constructor(private localeService: LocaleService) {
  }
  @Input() exclude: string = '';
  @Input() frontpage: boolean = false;
  private prods: Prod[] = [
    {
      title: 'Calculate Cars Quicker',
      acronym: 'CCQ',
      link: '/ccq',
      description: 'Calculate Every Import Cost in Seconds',
      icon: '/assets/icons/accounting.svg',
      available: ['en-IE']
    },{
      title: 'Check Your Car',
      acronym: 'CYC',
      link: '/cyc',
      description: 'Superior Car History and Providence Checks for Less',
      icon: '/assets/icons/zoom.svg',
      available: ['en-IE','en-GB']
    },
    {
      title: 'Find Cars Faster',
      acronym: 'FCF',
      link: '/fcf',
      description: 'Find the Ideal Cars to Import Based on Budget and Projected ROI',
      icon: '/assets/icons/money.svg',
      available: ['en-IE']
    },

    {
      title: 'TheCarSite Pro',
      acronym: 'TCS Pro ',
      link: '/tcspro ',
      description: 'Your One Stop Shop to Save Time, Cut Cost, Grow Profit and Sell Cars Faster.',
      icon: '/assets/icons/money-bag.svg',
      available: ['en-IE']
    },
    {
      title: 'Forex For Less',
      acronym: 'FFL',
      link: '/ffl',
      description: 'Stop Paying Retail Rates. No more ‘low costs’ with hidden marks ups. Real Institutional FX Rates.',
      icon: '/assets/icons/currencies.svg',
      available: ['en-IE','en-GB']
    },
    // {
    //   title: 'Cars to Forecourt',
    //   acronym: 'CTF',
    //   description: '= Let TheCarSite Handle the Entire Import Process from Start to Finish. You Find Them. We Get Them. You Sell Them.',
    //   icon: '/assets/icons/currencies.svg',
    // },

  ];
  public get productData(): Prod[] {
    return this.prods.filter(p => {
      return p.acronym.toLowerCase() !== this.exclude.toLowerCase() &&
        p.available.indexOf(<string>this.localeService.locale) > -1
    });
  }
}
