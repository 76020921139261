import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNotifications',
  standalone: true
})
export class FormatNotificationsPipe implements PipeTransform {

  transform(element: any): string {
    const notifications = JSON.parse(element.notifications);
    let notificationsFormatted = [];
    for (const key in notifications) {
      if (
        notifications[key] === null ||
        notifications[key] === 0 ||
        notifications[key].length === 0 ||
        key === 'id'
      ) {
        continue;
      }
      let value = notifications[key];
      if (typeof value === 'number') {
        value = Math.round(value);
      }
      notificationsFormatted.push(`${key}: ${value}`);
    }
    return notificationsFormatted.join("\n");
  }

}
