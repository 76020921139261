import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect, MatSelectChange} from "@angular/material/select";
import {CurrencyPipe, formatDate, KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {ApiService} from "../../../services/api.service";
import {MatError, MatInput} from "@angular/material/input";
import {CcqHomeComponent} from "../../../pages/home/ccq/ccq-home.component";
import {Subscription} from "rxjs";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatTooltip} from "@angular/material/tooltip";
import {MatSnackBar} from "@angular/material/snack-bar";

interface RosVersion {
  statCode: string;
  version: string;
  transmissionType: string;
  numberOfDoors: string;
  engineType: string;
  bodyType: string;
  engineCapacity: string;
  wltpco2: string;
  nedc: string;
  omsp: string;
  deprCode: string;
  nox: string;
}
const bodyTypes: { value: number, label: string }[] = [
  { value: 41, label: "Convertible" },
  { value: 58, label: "Drop-side lorry" },
  { value: 77, label: "Estate" },
  { value: 40, label: "Hatchback" },
  { value: 92, label: "MPV" },
  { value: 37, label: "Other" },
  { value: 56, label: "Other van" },
  { value: 48, label: "Pick up" },
  { value: 1, label: "Saloon" },
  { value: 42, label: "Sports coupe (non convertible)" },
  { value: 6, label: "Van" },
  { value: 50, label: "Van with side windows" }
];
@Component({
  selector: 'app-manual-vrt',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogTitle,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    NgForOf,
    KeyValuePipe,
    MatLabel,
    MatInput,
    MatProgressSpinner,
    NgIf,
    MatTooltip,
    CurrencyPipe,
    NgClass,
    MatError
  ],
  templateUrl: './manual-vrt.component.html',
  styleUrl: './manual-vrt.component.scss'
})

export class ManualVrtComponent implements OnInit {
  protected loading = true;
  protected vrtForm: FormGroup;
  protected makesAndModels: { [make: string]: string[] } | undefined;
  protected versions: RosVersion[] | undefined;
  private changeSubscriptions: Subscription[] = [];
  months: { index: number, name: string }[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: CcqHomeComponent, private dialog: MatDialog, private apiService: ApiService, private snack: MatSnackBar) {
    // Create array of months with names
    for (let i = 0; i < 12; i++) {
      const date = new Date(0, i);
      const monthName = formatDate(date, 'MMMM', 'en');
      if (monthName) {
        this.months.push({ index: i + 1, name: monthName });
      }
    }
    if (data.vehicleData.vrtDetails?.omsp_current) {
      data.vehicleData.vrtDetails.omsp_current = Math.round(Number(data.vehicleData.vrtDetails.omsp_current));
    }
    // console.log('vehicleDetails', data.vehicleData.vehicleDetails);
    // console.log('vrtDetails', data.vehicleData.vrtDetails);
    this.vrtForm = new FormGroup({
      make: new FormControl(data.vehicleData.vrtDetails?.make ?? (data.vehicleData.vehicleDetails?.make ? data.vehicleData.vehicleDetails?.make?.toUpperCase() : undefined)),
      model: new FormControl(data.vehicleData.vrtDetails?.model ?? (data.vehicleData.vehicleDetails?.model ? data.vehicleData.vehicleDetails?.model.toUpperCase() : undefined)),
      statCode: new FormControl(undefined),
      transmissionType: new FormControl(data.vehicleData.vrtDetails?.transmissionType ?? data.vehicleData.vehicleDetails?.transmissionType),
      engineType: new FormControl(data.vehicleData.vrtDetails?.engineType ?? data.vehicleData.vehicleDetails?.engineType),
      engineCapacity: new FormControl(data.vehicleData.vrtDetails?.engineCapacity ?? data.vehicleData.vehicleDetails?.engineCapacity,[Validators.max(10000),Validators.min(50)]),
      bodyType: new FormControl(data.vehicleData.vrtDetails?.bodyType ?? data.vehicleData.vehicleDetails?.bodyType),
      numberOfDoors: new FormControl(data.vehicleData.vrtDetails?.numberOfDoors ?? data.vehicleData.vehicleDetails?.numberOfDoors),
      mileage: new FormControl(data.vehicleData.vrtDetails?.mileage ?? data.vehicleData.vehicleDetails?.mileage),
      co2Emissions: new FormControl(data.vehicleData.vrtDetails?.wltpco2 ?? data.vehicleData.vehicleDetails?.co2Emissions, [Validators.max(500)]),
      nox: new FormControl(data.vehicleData.vrtDetails?.nox ?? data.vehicleData.vehicleDetails?.nox, [Validators.max(1)]),
      omsp_current: new FormControl(data.vehicleData.vrtDetails?.omsp_current),
      yearOfRegistration: new FormControl(data.vehicleData.vehicleDetails?.yearOfRegistration ?? data.vehicleData.vrtDetails?.yearOfRegistration, [Validators.min(1950), Validators.max(new Date().getFullYear())]),
      monthOfRegistration: new FormControl(data.vehicleData.vehicleDetails?.monthOfRegistration ?? data.vehicleData.vrtDetails?.monthOfRegistration),
    });
    this.vrtForm.disable();
  }
  async ngOnInit() {
    // Fetch makes and model
    const res = await this.apiService.getMakesAndModels()
      .catch(e => console.error(e));
    if (res?.success) {
      this.makesAndModels = res.data;
    }
    // Get versions, if make and model defined
    if (this.vrtForm.get('make')?.value && this.vrtForm.get('model')?.value) {
      await this.getVersions(<MatSelectChange>{value: this.vrtForm.get('model')?.value});
      // Select the correct version
      if (this.data.vehicleData.vrtDetails?.statCode) {
        this.vrtForm.get('statCode')?.setValue(this.data.vehicleData.vrtDetails.statCode);
        this.mode = 1;
      }
    }

    this.vrtForm.enable();
    for (let c in this.vrtForm.controls) {
      this.vrtForm.controls[c].valueChanges.subscribe((v) => {
        this.setFormMode(c,v);
      });
    }
    this.loading = false;
  }
  recalculate(){
    if (!this.vrtForm.valid) {
      return;
    }
    const data = this.vrtForm.value;
    this.vrtForm.disable();
    if (data.yearOfRegistration && data.monthOfRegistration) {
      const dateOfReg= new Date();
      dateOfReg.setFullYear(data.yearOfRegistration);
      dateOfReg.setMonth(data.monthOfRegistration);
      data.monthOfFirstRegistration = dateOfReg.toISOString();
    }
    this.loading = true;
    this.data.vehicleData.vrtDetails = data;
    this.data.getVrt(this.data.vehicleData.vehicleDetails?.id, data, 'manual')
      .then(res => {
        this.data.scroller.scrollToAnchor("vrtStart");
        this.dialog.closeAll()
      })
      .catch(e => {
        console.error(e);
        this.vrtForm.enable();
        this.loading = false;
        this.snack.open(e.message ?? 'Could not calculate the VRT');
      });
  }

  cancel() {
    this.dialog.closeAll();
  }

  protected readonly Object = Object;

  async getVersions($event: MatSelectChange) {
    this.versions = undefined;
    const model = $event.value;
    const make = this.vrtForm.get('make')?.value;
    const res = await this.apiService.getVersions(make, model)
      .catch(e => console.error(e));
    if (res?.success) {
      this.versions = res.data;
    }
  }

  updateValues($event: MatSelectChange) {
    const version = this.getVersion($event.value);
    // console.log('version', version);
    if (version) {
      // this.vrtForm.disable({emitEvent: false});
      // this.resetSubscriptions();
      if (version.transmissionType) {
        this.vrtForm.get('transmissionType')?.setValue(String(version.transmissionType),{emitEvent: false});
      }
      if (version.engineType) {
        this.vrtForm.get('engineType')?.setValue(String(version.engineType),{emitEvent: false});
      }
      if (version.engineCapacity !== null) {
        this.vrtForm.get('engineCapacity')?.setValue(version.engineCapacity,{emitEvent: false});
      }
      if (version.bodyType) {
        this.vrtForm.get('bodyType')?.setValue(String(version.bodyType),{emitEvent: false});
      }
      if (version.numberOfDoors) {
        this.vrtForm.get('numberOfDoors')?.setValue(String(version.numberOfDoors),{emitEvent: false});
      }
      if (version.wltpco2 !== null) {
        this.vrtForm.get('co2Emissions')?.setValue(version.wltpco2,{emitEvent: false});
      }
      if (version.nox !== null) {
        this.vrtForm.get('nox')?.setValue(version.nox,{emitEvent: false});
      } else {
        this.vrtForm.get('nox')?.setValue(this.data.vehicleData.vrtDetails?.nox ?? this.data.vehicleData.vehicleDetails?.nox,{emitEvent: false});
      }
      // this.setSubscriptions();
      // this.mode = 1;
      this.setFormMode('statCode',$event.value);
      // this.vrtForm.enable({emitEvent: false});
    }
  }
  private setFormMode(control: string, value: any) {
    let newMode = this.mode;
    if (control == 'omsp_current' && value) {
      newMode = 3;

      // OMSP
      // We also require co2 and enginetype
    } else if (control == 'statCode' && value) {
      newMode = 1;
      // Stat code mode
      // We also require mileage and year
    } else {
      if (this.vrtForm.value.statCode) {
        // We can only allow changing of mileage, year and month
        if (['mileage','yearOfRegistration','monthOfRegistation'].indexOf(control) === -1) {
          newMode = 2;
        }
      } else if (this.vrtForm.value.omsp_current) {
        // We can only allow changing of mileage, year and month, co2 and nox and engine type
        if (['mileage','yearofRegistration','monthOfRegistation','co2Emissions','nox','engineType'].indexOf(control) === -1) {
          newMode = 2;
        }
      } else {
        newMode = 2;
      }
    }
    if (newMode != this.mode) {
      this.vrtForm.disable({emitEvent: false});
      this.removeAllRequired();
      this.mode = newMode;
      if (newMode === 1) {
        // Statcode mode
        this.addRequired(['statCode','mileage','yearOfRegistration']);
        this.vrtForm.get('omsp_current')?.reset(undefined, {emitEvent: false});
      } else if (newMode === 2) {
        // Params with automatching
        // Reset OMSP and stat code
        this.vrtForm.get('statCode')?.reset(undefined, {emitEvent: false});
        this.vrtForm.get('omsp_current')?.reset(undefined, {emitEvent: false});
        this.addRequired(['make','model','engineType','bodyType','numberOfDoors','mileage','co2Emissions','yearOfRegistration']);
      } else if (newMode === 3) {
        // OMSP mode
        this.vrtForm.get('statCode')?.reset(undefined, {emitEvent: false});
        this.addRequired(['omsp_current','co2Emissions','engineType']);
        // We need either NOx or year of reg
        if (!this.vrtForm.value.yearOfRegistration && !this.vrtForm.value.nox) {
          this.addRequired(['nox']);
        }
      }
      this.vrtForm.enable({emitEvent: false});
      this.vrtForm.updateValueAndValidity({emitEvent: false, onlySelf: false});
      console.log('Mode', this.mode);
      console.log('Control', control);
    }
  }
  protected readonly bodyTypes = bodyTypes;
  mode: number = 0; // 1 == statcode, 2 == Params, 3 == OMSP
  protected get statCode(): number | null {
    return this.vrtForm.value.statCode ?? null;
  }
  protected get version(): string | undefined {
    return this.versions?.find(v => v.statCode == String(this.statCode))?.version;
  }
  private resetSubscriptions() {
    for (const s of this.changeSubscriptions) {
      s.unsubscribe();
    }
    this.changeSubscriptions = [];
  }

  private getVersion(statCode: number): RosVersion | undefined {
    return this.versions?.find(v => v.statCode == String(statCode));
  }

  protected readonly String = String;

  private removeAllRequired() {
    for (let c in this.vrtForm.controls) {
      // console.log(`Removing required from ${c}`);
      this.vrtForm.controls[c].removeValidators(Validators.required);
    }
  }
  private addRequired(controls: string[]) {
    for (let c of controls) {
      // console.log(`Adding required to ${c}`);
      this.vrtForm.controls[c]?.addValidators(Validators.required);
    }
  }
}
