<form [formGroup]="loginForm"
      (submit)="login()">
  <mat-form-field appearance="outline">
    <mat-label>E-mail</mat-label>
    <input matInput type="email" placeholder="E-mail" formControlName="username">
    <mat-error>Please enter a valid e-mail</mat-error>
  </mat-form-field>
  <br>
  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input matInput type="password" placeholder="Password" formControlName="password">
    <mat-error>Please enter a valid password</mat-error>
  </mat-form-field>
  <mat-action-list>
    <button color="primary" mat-flat-button type="submit">Login</button>
    <a role="button" [ngClass]="{disabled: disablePwdReminder}" (click)="forgotPwd()">Forgot your password?</a>
  </mat-action-list>
  <p class="error" *ngIf="forgotPwdError">{{forgotPwdError}}</p>
  <p class="ok-msg" *ngIf="pwdReminderSent">{{pwdReminderSent}}</p>
</form>

