import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserService} from "../../../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FirebaseError} from "@angular/fire/app/firebase";

@Component({
  selector: 'app-edit-email',
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, MatButton, MatFormField, MatInput, ReactiveFormsModule, MatLabel, MatDialogTitle, MatError],
  templateUrl: './edit-email.component.html',
  styleUrl: './edit-email.component.scss'
})
export class EditEmailComponent {
  fbError: string | undefined;
  protected emailForm: FormGroup;

  constructor(private userService: UserService, private dialog: MatDialog, private snack: MatSnackBar) {
    this.emailForm = new FormGroup({
      email: new FormControl(userService.email, [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  cancel() {
    this.dialog.closeAll();
  }

  async save() {
    if (this.emailForm.valid && this.emailForm.touched) {
      try {
        // Reauthenticate the user first
        await this.userService.reAuthenticate(this.emailForm.value.password);
        const newEmail = this.emailForm.value.email;
        await this.userService.updateEmail(newEmail);
        this.snack.open(`Please check your e-mail (${newEmail}) for a link to verify.`);
        this.dialog.closeAll();
      } catch (e: any) {
        this.fbError =  UserService.errorText(e.code ?? e.message ?? 'Could not update the e-mail address');
        this.emailForm.markAsUntouched();
      }
    }
  }
}
