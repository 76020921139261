import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transmissionType',
  standalone: true
})
export class TransmissionTypePipe implements PipeTransform {
  transform(value:string | number): string {
    return TransmissionTypePipe.lookup(value);
  }

  static lookup(value: string | number): string {
    switch(Number(value)) {
      case 1:
        return 'Manual';
      case 2:
        return 'Automatic';
      default:
        return 'Other';
    }
  }
}
