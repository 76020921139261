import { Injectable } from '@angular/core';
import {UserService} from "./user.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private hideMenuPaths = [
    '/register-form',
    '/register-details',
    '/choose-product',
  ];
  public hidden: boolean = false;
  constructor(protected userService: UserService, router: Router) {
    router.events.subscribe(e => {
      if (e.type === 15) {
        this.hidden = this.hideMenuPaths.indexOf(e.routerEvent.url) > -1;
      }
    });
  }
}
