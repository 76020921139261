import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {catchError} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {inject} from "@angular/core";
import {UpgradeComponent} from "../components/dialogs/upgrade/upgrade.component";

export const fairUseInterceptor: HttpInterceptorFn = (req, next) => {
  const dialog = inject(MatDialog);
  return next(req).pipe(catchError((error: HttpErrorResponse) => {
      if (error.status === 402) {
        // console.error('Fair usage', error);
        // The user has hit a fair usage cap
        // const response = <ApiResponse>JSON.parse(String(error.body));
        dialog.open(UpgradeComponent, {
          data: {
            error: error,
          },
        })
      }
    throw error;
  }));
};
