import { Injectable } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ShowVinComponent} from "../components/dialogs/show-vin/show-vin.component";

@Injectable({
  providedIn: 'root'
})
export class VinRevealService {

  public showVin: boolean = false;
  constructor(private dialog: MatDialog) {
  }
  public showVinDialog() {
    this.dialog.open(ShowVinComponent,{
      data: {
        vdc: this
      },
      id: 'showvin'
    });
  }
}
