import {HttpBackend, HttpClient, HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {catchError, lastValueFrom} from "rxjs";
import {inject} from "@angular/core";
import {UserService} from "../services/user.service";
import {Router} from "@angular/router";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const user = inject(UserService);
  const backend = inject(HttpBackend);
  const router = inject(Router);
  return next(req).pipe(catchError(async (error: HttpErrorResponse) => {
      if (error.status === 403) {
        if (user.isLoggedIn()) {
          // User is supposed to be logged in.
          // Attempt refresh and repeat
          const token = await user.refresh();
          if (token) {
            // Success, we can try again
            const newReq = req.clone({
              headers: req.headers.set('FIREBASE_ID_TOKEN',token)
            });
            const httpBackend = new HttpClient(backend);
            return await lastValueFrom(httpBackend.request(newReq)).catch((err: HttpErrorResponse) => {
              // No luck, throwing the error
              throw error;
            });
          }
        } else {
          // User is not logged in
          // Redirect the user to login
          await router.navigate(['/login']);
        }
      }
    // Other errors throw through
    throw error;
  }));
};
