export const environment = {
  apiServer: 'https://vatbroker.troll-tech.co.uk',
  firebaseConfig: {
    "projectId": "vatbroker",
    "appId": "1:893351576324:web:5f16ce6fdfb40d14af0651",
    "storageBucket": "vatbroker.appspot.com",
    "locationId": "europe-west",
    "apiKey": "AIzaSyAWSvCIGQmaPiL1qtHm3kejw1VzcJ0yaUI",
    "authDomain": "vatbroker.firebaseapp.com",
    "messagingSenderId": "893351576324",
    "measurementId": "G-YWFP6LY7XC"
  },
  stripe_key: "pk_live_51PMo9K09u5A5II2MFWPTEGcZVaOWcEtIaALavcp10nb4YioCZe63TbRjNHFqqHQWmI3L29cq2IxwqLtMBabt8kGO00F7F2QO0X"
}
