<div class="table searchResTable" role="table">
  <div class="table-segment">
    <div class="row">
      <div class="property">Make</div>
      <div class="value">{{ data.make }}</div>
    </div>
    <div class="row">
      <div class="property">Model</div>
      <div class="value">{{ data.model }}</div>
    </div>
    <div class="row">
      <div class="property">ROS Version Designation</div>
      <div class="value">{{ data.version }}</div>
    </div>
    <div class="row">
      <div class="property">Transmission</div>
      <div class="value">{{ data.transmissionType | transmissionType }}</div>
    </div>
    <div class="row">
      <div class="property">Engine Type</div>
      <div class="value">{{ data.engineType | engineType }}</div>
    </div>
    <div class="row">
      <div class="property">Engine Size</div>
      <div class="value">{{ data.engineCapacity | number }} cc</div>
    </div>
    <div class="row">
      <div class="property">Body Type</div>
      <div class="value">{{ data.bodyType | bodyType }}</div>
    </div>
    <div class="row">
      <div class="property">Number of Doors</div>
      <div class="value">{{ data.numberOfDoors }}</div>
    </div>
    <div class="row">
      <div class="property">Mileage</div>
      <div class="value">{{ data.mileage | number }} miles</div>
    </div>
    <div class="row">
      <div class="property">First Registered in Japan?</div>
      <div class="value">{{ data.firstRegisteredInJapan ? 'Yes' : 'No' }}</div>
    </div>
    <div class="row">
      <div class="property">EU Classification</div>
      <div class="value">{{ data.euClassification }}</div>
    </div>
    <div class="row">
      <div class="property">CO2 Emissions</div>
      <div class="value">{{ data.wltpco2 }} g/km</div>
    </div>
    <div class="row">
      <div class="property">NOx Emissions</div>
      <div class="value">{{ data.nox }} g/km</div>
    </div>
  </div>
  <div class="table-segment">

    <div class="row">
      <div class="property">VRT Statistical Code</div>
      <div class="value">{{ data.statCode }}</div>
    </div>


    <div class="row">
      <div class="property">Original OMSP</div>
      <div class="value">{{ data.omsp ? (data.omsp | currency: 'EUR' : 'symbol': '1.0-0') : 'n/a' }}</div>
    </div>
    <div class="row">
      <div class="property">Current OMSP</div>
      <div class="value">
        {{ data.omsp_current | currency: 'EUR' : 'symbol': '1.0-0' }}
        @if (data.source == 'tcs-estimated-omsp' || data.source == 'tcs-prestige') {
          <ng-container *ngTemplateOutlet="omspAsterisk"></ng-container>
        }
      </div>
    </div>
    <div class="row">
      <div class="property">OMSP Adjustment for Month</div>
      <div class="value">{{ data.monthlyAdjustment ? (1 - data.monthlyAdjustment | percent: '1.0-0') : 'n/a'}}</div>
    </div>
    <div class="row">
      <div class="property">OMSP Mileage Reduction</div>
      <div class="value">{{ data.mileageRed ? (data.mileageRed | currency: 'EUR' : 'symbol': '1.0-0') : 'n/a' }} </div>
    </div>
    <div class="row">
      <div class="property">Depreciation Code</div>
      <div class="value">{{ data.deprCode ?? 'n/a' }} </div>
    </div>
    <div class="row">
      <div class="property">Depreciation Rate</div>
      <div class="value">{{ data.deprRate ? (data.deprRate | percent: '1.1-1') : 'n/a' }}</div>
    </div>
    <div class="row">
      <div class="property">CO2 Charge Rate</div>
      <div class="value">{{ data.unmodifiedVRTAmount  | percent: '1.1-1' }}</div>
    </div>
    <div class="row">
      <div class="property">CO2 Charge</div>
      <div class="value">{{ data.co2Charges | currency: 'EUR' : 'symbol': '1.0-0' }} </div>
    </div>
    <div class="row">
      <div class="property">NOx Charge</div>
      <div class="value">{{ data.noxCharge | currency: 'EUR' : 'symbol': '1.0-0' }}</div>
    </div>
    <div class="row">
      <div class="property">Total VRT Charge</div>
      <div class="value">{{ data.vrtEuro | currency: 'EUR' : 'symbol': '1.0-0' }}</div>
    </div>
  </div>
</div>
<ng-template #omspAsterisk>
  <span class="omspAsterisk" matTooltip="We have estimated the current OMSP, which has a margin of error of up to 15 % for the final VRT calculation. Use the results with caution.">*</span>
</ng-template>
