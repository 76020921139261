import {Component, Input} from "@angular/core";
import {RouterLink} from "@angular/router";
import {UserService} from "../../services/user.service";
import {NgIf} from "@angular/common";
import {NavService} from "../../services/nav.service";
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: "app-nav",
  standalone: true,
  imports: [
    RouterLink,
    NgIf
  ],
  templateUrl: "./nav.component.html",
  styleUrl: "./nav.component.scss"
})
export class NavComponent {
  @Input() showExternal = false;

  constructor(protected userService: UserService, protected nav: NavService, protected localeService: LocaleService) {

  }

}
