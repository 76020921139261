import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProductsComponent } from "../../../components/products/products.component";
import { VrmSearchComponent } from "../../../components/vrm-search/vrm-search.component";
import { PricetagComponent } from "../../../components/pricetag/pricetag.component";
import { NgOptimizedImage } from "@angular/common";
import {MatAnchor, MatButton} from "@angular/material/button";
import { RouterLink } from "@angular/router";
import { ReviewsComponent } from "../../../components/reviews/reviews.component";
import { StatbitsComponent } from "../../../components/statbits/statbits.component";
import { HeroBgService } from "../../../services/hero-bg.service";
import { ProdDetailsComponent } from "../../../components/prod-details/prod-details.component";

@Component({
  selector: "app-ccq",
  standalone: true,
  imports: [
    ProductsComponent,
    VrmSearchComponent,
    PricetagComponent,
    NgOptimizedImage,
    MatButton,
    RouterLink,
    ReviewsComponent,
    StatbitsComponent,
    ProdDetailsComponent,
    MatAnchor,
  ],
  templateUrl: "./ccq.component.html",
  styleUrl: "./ccq.component.scss",
})
export class CcqComponent implements OnInit, OnDestroy {
  constructor(private hero: HeroBgService) {

  }

  ngOnInit() {
    this.hero.setBg("/assets/img/race-car.jpg", "center 6%", undefined, '80%');
  }

  ngOnDestroy() {
    this.hero.reset();
  }
}
