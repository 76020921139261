<h2 mat-dialog-title>Update your payment method</h2>
<mat-dialog-content>
  <img ngSrc="/assets/payment/stripe.svg" width="468" height="222.5" alt="Stripe">
  <p>Click this button to open Stripe for verification of a new payment method.</p>

<!--  <p>Please enter your new card details:</p>-->
<!--  <form [formGroup]="paymentFormGroup" class="paymentDetails">-->
<!--    <mat-form-field class="cardnumber">-->
<!--      <mat-label>Card number</mat-label>-->
<!--      <input matInput type="text" formControlName="number">-->
<!--      <mat-error>Please provide a valid payment card number</mat-error>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="month">-->
<!--      <mat-label>Month</mat-label>-->
<!--      <input matInput type="exp_month" formControlName="exp_month" placeholder="MM" min="1" max="12">-->
<!--      <mat-error>Please provide a valid month of expiry</mat-error>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="year">-->
<!--      <mat-label>Year</mat-label>-->
<!--      <input matInput type="exp_year" formControlName="exp_year" placeholder="YY" min="24">-->
<!--      <mat-error>Please provide a valid year of expiry</mat-error>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="cvc">-->
<!--      <mat-label>CVC</mat-label>-->
<!--      <input matInput type="cvc" formControlName="cvc">-->
<!--      <mat-error>Please provide a valid CVC code</mat-error>-->
<!--    </mat-form-field>-->
<!--  </form>-->
<!--  &lt;!&ndash;  <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="intent === undefined"></mat-progress-spinner>&ndash;&gt;-->
<!--  @if (intent?.next_action?.type == 'redirect_to_url' && intent?.next_action?.redirect_to_url?.url) {-->
<!--    <a mat-raised-button role="link" color="primary" [href]="intent?.next_action?.redirect_to_url?.url" target="_blank"-->
<!--       rel="noopener">Click to update your payment method</a>-->
<!--  }-->
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="validate(); addButton.disabled = true" #addButton>Add new payment method</button>
</mat-dialog-actions>
