import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {MatDialog} from "@angular/material/dialog";
import {BugComponent} from "../components/dialogs/bug/bug.component";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class BugService {
  submitted: boolean = false;
  private data: any;
  constructor(private apiService: ApiService, private dialog: MatDialog) { }

  report(service: string, data: any) {
    this.dialog.open(BugComponent, {
      data: {
        'service': service,
        'bugs': this
      },
      id: 'bugReport'
    });
    this.data = data;
  }
  async submit(form: FormGroup) {
    await this.apiService.submitBug(form.value, this.data);
    this.submitted = true;
  }
  close() {
    this.dialog.getDialogById('bugReport')?.close();
  }
}
