<h2 mat-dialog-title>Invoices</h2>
<mat-dialog-content>
  <div role="table" class="invoices">
    <div class="rowheader">Date</div>
    <div class="rowheader sum">Sum</div>
    <div class="rowheader"></div>
    <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="invoices === undefined"></mat-progress-spinner>
    @if (invoices?.length === 0) {
      <div class="info">Could not find any invoices.</div>
    }
    @for (invoice of invoices; track invoice.id) {
      <div class="date">{{Number(invoice.created) * 1000 | date}}</div>
      <div class="sum">{{Number(invoice.total) / 100 | currency : invoice.currency.toUpperCase() : 'symbol' }}</div>
      <div class="link"><a [href]="invoice.hosted_invoice_url" target="_blank" rel="noopener">Download</a></div>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="dialog.closeAll();">Close</button>
</mat-dialog-actions>
