import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatAnchor} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {CommerceService} from "../../../services/commerce.service";

@Component({
  selector: "app-subscription-required-dialog",
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatAnchor,
    RouterLink
  ],
  templateUrl: "./subscription-required-dialog.component.html",
  styleUrl: "./subscription-required-dialog.component.scss"
})
export class SubscriptionRequiredDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) protected data: any, protected dialog: MatDialog) {
    if (data["service"]) {
      data["feature"] = CommerceService.prodName(data["service"]);
    }
  }

}
