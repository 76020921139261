<!--<mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>-->
<form class="signupForm" [formGroup]="detailsForm" [hidden]="!loaded">
  <input type="hidden" name="stripe_id">
  <mat-form-field appearance="outline">
    <mat-label>Your Name</mat-label>
    <input matInput type="text" formControlName="name">
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Business Name</mat-label>
    <input matInput type="text" formControlName="business_name" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of business_matches; track option) {
        <mat-option [value]="option.displayName.text" (click)="selectMatch(option)">{{option.displayName.text}}, {{option.formattedAddress}}</mat-option>
      }
    </mat-autocomplete>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Business Address</mat-label>
    <input matInput type="text" formControlName="business_address">
    <mat-error></mat-error>
  </mat-form-field>
  <input type="hidden" name="postal_code">
  <input type="hidden" name="postal_town">
  <input type="hidden" name="country">
  <mat-form-field appearance="outline">
    <mat-label>VAT number (if applicable)</mat-label>
    <input matInput type="text" formControlName="business_vat">
    <mat-error></mat-error>
  </mat-form-field>
  <div>
    <!--        <mat-label>Phone Number</mat-label>-->
    <!--        <input matInput type="text" formControlName="business_phone">-->
    <ngx-material-intl-tel-input [fieldControl]="businessPhoneControl" [autoSelectedCountry]="autoselectedCountry()" [autoIpLookup]="false" [iconMakeCall]="false" [hidePhoneIcon]="true" [visibleCountries]="['ie','gb']" [enableSearch]="false" [textLabels]="{mainLabel: '', codePlaceholder: 'Code', searchPlaceholderLabel: 'Search', noEntriesFoundLabel: 'No countries found', nationalNumberLabel: 'Phone number', hintLabel: 'Select country and type your phone number', invalidNumberError: 'Number is not valid', requiredError: 'This field is required'}"> </ngx-material-intl-tel-input>
    <!--        <mat-error></mat-error>-->
  </div>
</form>
