<h2 mat-dialog-title>Manual VRT calculation <span class="spacer"></span> <mat-progress-spinner diameter="15" mode="indeterminate" *ngIf="loading"></mat-progress-spinner></h2>
<mat-dialog-content>
  <p>You can specify the parameters used to calculate the VRT to provide an estimate.</p>
  <p>Please note that entering incorrect values will result in an inaccurate estimate.</p>
  <form [formGroup]="vrtForm">
    <mat-form-field class="half" [ngClass]="{active: [1,2].indexOf(mode) > -1, inactive: [1,2].indexOf(mode) === -1}">
      <mat-label>Make</mat-label>
      <mat-select formControlName="make" #make (selectionChange)="vrtForm.get('model')?.setValue(undefined)">
        @if (makesAndModels) {
          <mat-option *ngFor="let make of Object.keys(makesAndModels)" [value]="make">{{make}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="half" [ngClass]="{active: [1,2].indexOf(mode) > -1, inactive: [1,2].indexOf(mode) === -1}">
      <mat-label>Model</mat-label>
      <mat-select formControlName="model" [disabled]="!make.value" #model (selectionChange)="getVersions($event)">
        @if (makesAndModels && make.value) {
          <mat-option *ngFor="let model of makesAndModels[make.value]" [value]="model">{{model}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full"  [ngClass]="{active: mode === 1, inactive: mode && mode !== 1}">
      <mat-label>Version</mat-label>
      <mat-select formControlName="statCode" [disabled]="!model.value" (selectionChange)="updateValues($event)">
        @if (versions && model.value) {
          <mat-option *ngFor="let version of versions" [value]="String(version.statCode)">{{version.version}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field [ngClass]="{active: [1,2].indexOf(mode) > -1, inactive: [1,2].indexOf(mode) === -1}">
      <mat-label>Month of Registration</mat-label>
      <mat-select formControlName="monthOfRegistration">
        @for (month of months; track month.index) {
          <mat-option [value]="String(month.index)">{{month.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field [ngClass]="{active: [1,2].indexOf(mode) > -1, inactive: [1,2].indexOf(mode) === -1}">
      <mat-label>Year of Registration</mat-label>
      <input matInput type="number" step="1" formControlName="yearOfRegistration">
      <mat-error>The year must be after 1950 and not in the future.</mat-error>
    </mat-form-field>
    <mat-form-field  [ngClass]="{active: [1,2].indexOf(mode) > -1, inactive: [1,2].indexOf(mode) === -1}">
      <mat-label>Mileage (miles)</mat-label>
      <input matInput type="number" step="100" formControlName="mileage" min="0">
    </mat-form-field>
    <mat-form-field [ngClass]="{active: mode === 2, inactive: mode && mode !== 2}">
      <mat-label>Transmission</mat-label>
      <mat-select formControlName="transmissionType">
          <mat-option value="1">Manual</mat-option>
          <mat-option value="2">Automatic</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [ngClass]="{active: mode >= 2, inactive: mode && mode < 2}">
      <mat-label>Engine Type</mat-label>
      <mat-select formControlName="engineType">
        <mat-option value="1">Petrol</mat-option>
        <mat-option value="2">Diesel</mat-option>
        <mat-option value="3">Electric</mat-option>
        <mat-option value="8">Petrol mild hybrid</mat-option>
        <mat-option value="10">Diesel mild hybrid</mat-option>
        <mat-option value="15">Diesel hybrid</mat-option>
        <mat-option value="16">Petrol hybrid</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [ngClass]="{active: mode === 2, inactive: mode && mode !== 2}">
      <mat-label>Engine size (cc)</mat-label>
      <input matInput type="number" step="1" min="0" formControlName="engineCapacity">
      <mat-error>Engine size must be minimum 50 cc and no more than 10,000 cc</mat-error>
    </mat-form-field>
    <mat-form-field [ngClass]="{active: mode === 2, inactive: mode && mode !== 2}">
      <mat-label>Body Type</mat-label>
      <mat-select formControlName="bodyType">
        <mat-option *ngFor="let option of bodyTypes" [value]="String(option.value)">
          {{option.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [ngClass]="{active: mode === 2, inactive: mode && mode !== 2}">
      <mat-label>Number of Doors</mat-label>
      <mat-select formControlName="numberOfDoors">
        @for (i of [2,3,4,5,6,7]; track i) {
          <mat-option [value]="String(i)">{{i}}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field [ngClass]="{active: [2,3].indexOf(mode) > -1, inactive: [2,3].indexOf(mode) === -1}">
      <mat-label>CO2 emissions (g/km)</mat-label>
      <input matInput type="number" step="1" formControlName="co2Emissions" min="0">
      <mat-error>The CO2 value must be less than 500 g/km</mat-error>
    </mat-form-field>
    <mat-form-field  [ngClass]="{active: [2,3].indexOf(mode) > -1, inactive: [2,3].indexOf(mode) === -1}">
      <mat-label>NOx emissions (g/km)</mat-label>
      <input matInput type="number" step="0.01" formControlName="nox" min="0">
      <mat-error>The NOx value must be less than 1 g/km</mat-error>
    </mat-form-field>
    <mat-form-field [ngClass]="{active: mode === 3, inactive: mode && mode !== 3}">
      <mat-label matTooltip="Current Open Market Sale Price">Current OMSP (&euro;)</mat-label>
      <input matInput type="number" step="1" formControlName="omsp_current" min="0">
    </mat-form-field>
  </form>
  @if (statCode) {
    <p class="explainer">You have selected a specific version ({{version}}) with statistical code {{statCode}}.<br>
      We will use that statistical code to calculate the VRT along with the data entered into the highlighed inputs.</p>
  } @else if (vrtForm.get('omsp_current')?.touched) {
    <p class="explainer">You have provided your own Current Open Market Sale Price (OMSP) of {{vrtForm.get('omsp_current')?.value | currency: 'EUR' : 'symbol': '1.0-0': 'en-IE'}}.<br>
      We will use this Current OMSP to estimate the VRT as accurately as possible. I.e., we won't attempt to match the vehicle parameters to a Statistical Code or provide an OMSP estimate, rather we will calculate the VRT based on the Current OMSP, CO2 and NOx values given.<br>
      Such calculations should be taken with considerable caution as it is far safer to match to a Statistical Code.</p>
  } @else if (vrtForm.touched) {
    <p class="explainer">You have not selected a specific version of the vehicle. Therefore, we will attempt to match the car using the values in the highlighted inputs above and find a Statistical Code, which will be shown in your report in the Statistical Code field.<br>
      However, if we cannot match the car with the parameters given, we will instead calculate the VRT based on the Current OMSP, CO2 and NOx values given.
      This will show as a disclaimer in the VRT section of your report as "Estimated OMSP". Such calculations should be taken with considerable caution as it is far safer to match to a Statistical Code.</p>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <span class="spacer"></span>

  <button mat-raised-button color="primary" (click)="recalculate()" [disabled]="vrtForm.untouched || vrtForm.invalid">Calculate VRT</button>
</mat-dialog-actions>
