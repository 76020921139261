import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {CurrencyPipe, DatePipe, NgIf} from "@angular/common";
import {ApiService} from "../../../services/api.service";
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatError} from "@angular/material/form-field";

interface Invoice {
  id: string;
  currency: string;
  hosted_invoice_url: string;
  total: string;
  created: string; // Unix TS
}

@Component({
  selector: 'app-invoices-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    CurrencyPipe,
    DatePipe,
    MatDialogActions,
    MatButton,
    MatProgressSpinner,
    NgIf,
    MatError
  ],
  templateUrl: './invoices-dialog.component.html',
  styleUrl: './invoices-dialog.component.scss'
})
export class InvoicesDialogComponent implements OnInit {
  protected invoices : Invoice[] | undefined;
  constructor(private apiService: ApiService, public dialog: MatDialog) {
  }
  async ngOnInit() {
      const response = await this.apiService.getInvoices();
      if (response?.success) {
        this.invoices = response.data;
      }
  }

  protected readonly Number = Number;
}
