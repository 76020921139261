<section class="header">
  <h1>Notifications</h1>
</section>
<section class="pageContent">
  <div class="prodCard">
    <p>You are subscribed to the notifications below. We will send you e-mails alerting you of new vehicles that match the filters defined in the table below.</p>
    <p>You can edit or delete these filters in the table below.</p>
    <p>You can also create a new notification now:</p>
    <button mat-raised-button color="primary" (click)="new(notifications)">Setup a new notification</button>
  </div>
  <app-notifications #notifications></app-notifications>
</section>
