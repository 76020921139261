<section>
  <h2>
    @if (frontpage) {
      Here are the Products that will help you…
    } @else {
      Check out other Products From TheCarSite That Will help you…
    }
    <ul>
      <li>Save Time.</li>
      <li>Cut Costs.</li>
      <li>Grow Profits.</li>
      <li>Sell Cars Faster.</li>
    </ul>
  </h2>
  <div class="productContainer">
    @for (p of productData; track p.acronym) {
      <div>
        <img [ngSrc]="p.icon" height="25" width="25" [alt]="p.acronym">
        <h3>{{p.acronym}}</h3>
        <p>{{p.description}}</p>
        <a mat-raised-button [routerLink]="[p.link]">Learn more</a>
      </div>
    }
  </div>
</section>
