import {Component, OnInit} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import { ApiService} from "../../services/api.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {DecimalPipe, NgIf} from "@angular/common";
import {MatSortHeader} from "@angular/material/sort";
import {Quote, QuotesService} from "../../services/quotes.service";


@Component({
  selector: 'app-quotes',
  standalone: true,
  imports: [MatTable, MatProgressSpinner, NgIf, MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatSortHeader, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, DecimalPipe],
  templateUrl: './quotes.component.html',
  styleUrl: './quotes.component.scss'
})
export class QuotesComponent implements OnInit {
  protected quotesDataSource = new MatTableDataSource<Quote>([]);
  public displayedColumns = ['ts','version','total','status'];
  loading = true;

  constructor(private apiService: ApiService, protected quoteService: QuotesService) {

  }

  async ngOnInit() {
    // Get data
    this.quotesDataSource = new MatTableDataSource<Quote>(await this.apiService.getUserQuotes());
    this.loading = false;
  }
}

