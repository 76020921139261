<div class="prodCard">
  <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>
  <table mat-table [dataSource]="users">
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-mail</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>
    <ng-container matColumnDef="emailVerified">
      <th mat-header-cell *matHeaderCellDef>E-mail verified</th>
      <td mat-cell *matCellDef="let element">
        @if (element.emailVerified) {
          <mat-icon class="green-icon">check</mat-icon>
        } @else {
          <mat-icon color="primary" matTooltip="Click to re-send verification e-mail" (click)="resendVerificationEmail(element)">close</mat-icon>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>Registered</th>
      <td mat-cell *matCellDef="let element">{{ element.metadata.createdAt?.date | date }}</td>
    </ng-container>
    <ng-container matColumnDef="lastLoginAt">
      <th mat-header-cell *matHeaderCellDef>Last login</th>
      <td mat-cell *matCellDef="let element">{{ (element.metadata.lastLoginAt?.date | date) ?? 'Never' }}</td>
    </ng-container>
    <ng-container matColumnDef="lastBill">
      <th mat-header-cell *matHeaderCellDef>Last invoice</th>
      <td mat-cell *matCellDef="let element">
        @if (element.customClaims?.lastBill) {
          {{ (element.customClaims?.lastBill * 1000 | date) }}
        } @else {
          None
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="service">
      <th mat-header-cell *matHeaderCellDef>Product</th>
      <td mat-cell *matCellDef="let element">{{ UserService.serviceName(element.customClaims?.service) ?? 'None' }}</td>
    </ng-container>
    <ng-container matColumnDef="validate">
      <th mat-header-cell *matHeaderCellDef>Validate</th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="validate(element)">Validate service</button>
      </td>
    </ng-container>
    <ng-container matColumnDef="quota">
      <th mat-header-cell *matHeaderCellDef>Quota</th>
      <td mat-cell *matCellDef="let element">
        @if (element.customClaims?.quota) {
          {{ element.customClaims?.quota }}
        } @else if (['admin', 'editor'].indexOf(getRole(element)) > -1) {
          Unlimited
        } @else {
          None
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Privilege</th>
      <td mat-cell *matCellDef="let element">
        <mat-select [value]="getRole(element)" (valueChange)="setRole($event, element)">
          <mat-option value="">None</mat-option>
          <mat-option value="editor">Editor</mat-option>
          <mat-option value="admin">Admin</mat-option>
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns_users"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns_users;"></tr>
  </table>
</div>
