<div class="accessDialogContainer">
  <h2 mat-dialog-title>Access to {{ data['feature'] ?? 'this feature' }} requires a valid subscription</h2>
  <mat-dialog-content>
    @switch (data.feature) {
      @case ("Find Cars Faster") {
        <p>Find your ideal car to import based on Your Budget and Your Projected or Desired ROI.</p>
        <p>You can Search in a whole variety of Dealer specific terms for example - Total Cost Of Car, Exp. Profit, ROI,
          Time To Sale Or All The Usuals E.G. Make, Model Etc.</p>
        <p>Alternatively you can Browse all cars we think could be profitable using Dealer.</p>
      }
      @case ("Calculate Cars Quicker") {
        <p>Calculate every import cost in seconds by typing your desired car reg into CCQ and click ‘Go’.</p>
        <p>Every cost including VAT, VRT, NOx, Customs, Forex and Shipping will be presented to you on screen and
          emailed to you. Calculate as many cars as you wish there is no limit to the number of searches.</p>
      }
      @case ("Check Your Car") {
        <p>Access Superior provenance checks with Check Your Car.</p>
        <p>Type in the reg and click ‘Go’.</p>
        <p>Watch the data dance and highlight what you need to know on screen and in your emails.</p>
        <p>Rest assured that CYC has the most up to date data available in the market.</p>
      }
    }
    <p class="loginPrompt">Please login or register for access</p>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <a mat-raised-button color="primary" (click)="dialog.closeAll()" routerLink="/login">Login</a>
    <a mat-raised-button color="primary" (click)="dialog.closeAll()" routerLink="/register">Register</a>
  </mat-dialog-actions>
</div>
