import {Injectable} from "@angular/core";
import Dexie from "dexie";
import {UserService} from "./user.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FeedbackComponent} from "../components/dialogs/feedback/feedback.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: "root"
})
export class FeedbackService extends Dexie {
  private fbDialog: MatDialogRef<FeedbackComponent> | undefined;

  constructor(private userService: UserService, private dialog: MatDialog, private snack: MatSnackBar, private apiService: ApiService) {
    super("tcs");
    this.version(1).stores({
      log: "ts,vrm,endpoint,uuid,feedback"
    });
  }

  /**
   * Log the action
   * Check if we need to display a feedback dialog
   * @param vrm
   * @param endpoint
   */
  public async trigger(vrm: string, endpoint: string): Promise<void> {
    const ts = await this.logAction(vrm, endpoint);
    await this.toggleFeedback(vrm, endpoint, ts);
  }

  /**
   * Log the action
   * @param vrm
   * @param endpoint
   * @private
   */
  private async logAction(vrm: string, endpoint: string): Promise<string> {
    const ts = new Date().toISOString();
    await this.table("log").add({
      ts: ts,
      vrm: vrm,
      endpoint: endpoint,
      uuid: this.userService.uuid
    });
    return ts;
  }

  /**
   * Check if we need to show a feedback dialog
   * We show it every 3 actions, but max 3 times in a day
   * @private
   */
  private async toggleFeedback(vrm: string, endpoint: string, ts: string): Promise<void> {
    // Get the logs for past 24h
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const logs = await this.table("log")
      .where("ts")
      .above(yesterday)
      .reverse()
      .toArray();
    let nFB = 0;
    for(let log of logs) {
      if (log.feedback && log.endpoint == endpoint ) {
        nFB++;
      }
      if (nFB >=3) {
        // Already got feedback 3 times in past 24 hours for this endpoint
        return;
      }
    }
    if (logs.length > 0 && logs.length % 3 === 0) {
      this.showFeedbackModal(vrm,endpoint);
      await this.updateFeedback(ts);
    }
  }

  private showFeedbackModal(vrm: string, endpoint: string) {
    setTimeout(() => {
      this.fbDialog = this.dialog.open(FeedbackComponent,{
        data: {
          service: endpoint,
          vrm: vrm,
        },
        disableClose: true
      });
    }, 5000);
  }
  public async feedback(fb: 0 | 1, vrm: string, service: string) {
    this.fbDialog?.close();
    this.snack.open("Thank you for your feedback!",undefined,{duration: 3000, panelClass: "ok", verticalPosition: "bottom"});
    await this.apiService.submitFeedback(fb,vrm, service);
  }

  /**
   * Mark the log entry as having shown the dialog
   * @param ts
   * @private
   */
  private updateFeedback(ts: string) {
    this.table("log").update(ts, {
      feedback: true
    }).then(updated => {
      console.log(`Updated: ${updated}`);
    }).catch(e => console.error(`Could not update ${ts}`, e));
  }
}
