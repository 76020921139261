<div class="statBits">
@for (statbit of statbits_sorted; track $index) {
  <div class="statBit">
    <div class="value">{{statbit.value}}</div>
    @if (statbit.valueAnnotation) {
      <div class="valueAnnotation">{{statbit.valueAnnotation}}</div>
    }
    <p class="text">{{statbit.text}}</p>
  </div>
}
</div>
