<h2 mat-dialog-title>{{ data.data.make }} {{ data.data.model }}</h2>
<mat-dialog-content>
  @if (equivs?.data) {
  <div role="table" class="searchResTable valsRight">
    <div role="cell" class="columnheader">Source</div>
    <div role="cell" class="columnheader">Year</div>
    <div role="cell" class="columnheader">Mileage</div>
    <div role="cell" class="columnheader">Price</div>
    <ng-container *ngFor="let res of equivs?.data">
      <div role="cell" class="value">
        @if (res.gone === null) {
          <a [href]="FcfService.getSource(res).url" target="_blank" rel="noopener noreferrer" class="source">{{ FcfService.getSource(res).source ?? res.source }}</a>
        } @else {
          <span class="gone">{{ FcfService.getSource(res).source ?? res.source }}</span>
        }
      </div>
      <div role="cell" class="value">{{ res.yearOfRegistration }}</div>
      <div role="cell" class="value">{{ res.kms | number }} km</div>
      <div role="cell" class="value euro">{{ res.price | number: '1.2-2' }}</div>
    </ng-container>
  </div>
  } @else if (equivs && equivs.data === null) {
    <p>We could not find any equivalent vehicles currently on the Irish market.</p>
    @if (equivs?.msg) {
      <p>{{equivs?.msg}}</p>
    }
  } @else if (loadingEquivs) {
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
  }
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="dialog.closeAll()" mat-flat-button>Close</button>
</mat-dialog-actions>
