import {Component, OnInit} from "@angular/core";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {ApiService} from "../../services/api.service";
import {LocaleService} from "../../services/locale.service";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {NgxMaterialIntlTelInputComponent} from "ngx-material-intl-tel-input";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatError, MatInput} from "@angular/material/input";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgStyle} from "@angular/common";

interface BusinessMatch {
  id: string,
  displayName: {
    text: string, languageCode: string
  },
  formattedAddress: string,
  internationalPhoneNumber: string,
  addressComponents: { longText: string, shortText: string, languageCode: string, types: string[] }[]
}

@Component({
  selector: "app-business-details-form",
  standalone: true,
  imports: [MatAutocomplete, MatAutocompleteTrigger, NgxMaterialIntlTelInputComponent, MatFormField, MatInput, MatOption, ReactiveFormsModule, MatError, MatLabel, MatProgressSpinner, NgStyle,],
  templateUrl: "./business-details-form.component.html",
  styleUrl: "./business-details-form.component.scss",
})
export class BusinessDetailsFormComponent implements OnInit {
  public detailsForm: FormGroup;
  public business_matches: BusinessMatch[] = [];
  public businessPhoneControl: FormControl;
  protected loaded: boolean = false;

  constructor(protected user: UserService, private apiService: ApiService, protected localeService: LocaleService, private userService: UserService) {
    this.businessPhoneControl = new FormControl("", Validators.required);
    this.detailsForm = new FormGroup({
      name: new FormControl(user.name, Validators.required),
      business_name: new FormControl("", Validators.required),
      business_address: new FormControl("", Validators.required),
      postal_code: new FormControl(""),
      postal_town: new FormControl(""),
      country: new FormControl(""),
      business_vat: new FormControl(""),
      business_phone: this.businessPhoneControl,
      stripe_id: new FormControl(""),
    });
  }

  async ngOnInit() {
    this.detailsForm.disable({onlySelf: true});
    try {
      const d = await this.userService.getBusinessDetails();
      this.detailsForm.setValue(d);
    } catch (e) {
      console.error(e);
    }
    this.detailsForm.enable({onlySelf: true});
    this.detailsForm
      .get("business_name")
      ?.valueChanges.pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(async (name: string) => {
        const res = await this.apiService.lookupBusiness(name);
        if (res.success) {
          this.business_matches = res.data;
        }
      });
  }

  public selectMatch(match: BusinessMatch) {
    if (match["formattedAddress"]) {
      this.detailsForm.get("business_address")?.setValue(match["formattedAddress"]);
    }
    if (match["internationalPhoneNumber"]) {
      this.detailsForm.get("business_phone")?.setValue(match["internationalPhoneNumber"]);
    }
    if (match["addressComponents"]) {
      this.detailsForm.get("postal_code")?.setValue(match["addressComponents"].find(component => component.types.includes("postal_code"))?.longText);
      this.detailsForm.get("postal_town")?.setValue(match["addressComponents"].find(component => component.types.includes("postal_town"))?.longText);
      this.detailsForm.get("country")?.setValue(match["addressComponents"].find(component => component.types.includes("country"))?.longText);
    }
  }

  saveBusinessDetails(): boolean {
    if (this.detailsForm.valid) {
      try {
        // Save details to DB
        this.apiService.updateBusinessDetails(this.detailsForm.value);
        // Save name to FB
        this.userService.setName(this.detailsForm.value.name);
        // Update the business details in the user service
        this.userService.businessDetails = this.detailsForm.value;
        return true;
      } catch (e) {
        console.error(e);
      }
    }
    return false;
  }

  autoselectedCountry(): string {
    return this.localeService.locale === "en-GB" ? "gb" : "ie";
  }
}
