import {Component, Inject, OnInit} from "@angular/core";
import {CurrencyPipe, DecimalPipe, NgClass, NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {CommerceService, Product} from "../../services/commerce.service";
import {Router, RouterLink} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {MatCheckbox} from "@angular/material/checkbox";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {LocaleService} from "../../services/locale.service";
import {MatSlider, MatSliderRangeThumb, MatSliderThumb} from "@angular/material/slider";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: "app-buy-credits",
  standalone: true,
  imports: [DecimalPipe, MatButton, MatProgressSpinner, RouterLink, NgClass, MatCheckbox, NgIf, MatSlider, MatSliderRangeThumb, ReactiveFormsModule, MatSliderThumb, CurrencyPipe],
  templateUrl: "./buy-credits.html",
  styleUrl: "./buy-credits.scss",
})
export class BuyCreditsComponent implements OnInit {
  protected paymentInitiated: boolean = false;
  protected slideController: FormControl;
  protected creditProduct: Product | undefined;

  constructor(public commerce: CommerceService, private apiService: ApiService, @Inject(LOCAL_STORAGE) private storage: StorageService, protected localeService: LocaleService, private router: Router, private snackBar: MatSnackBar,) {
    this.slideController = new FormControl(10);

  }
ngOnInit() {
  this.commerce.gotProds.subscribe(() => {
    this.creditProduct = this.commerce.creditProduct();
    // console.log(this.creditProduct);
    if (!this.creditProduct) {
      // We do not have a credit product
      this.snackBar.open("Could not find the credit product", undefined, {panelClass: "snack-error"});
      this.router.navigate(['/choose-product']);
    }
  });
}

  get total(): number {
    return this.slideController.value * Number(this.creditProduct?.price);
  }

  get payload(): any {
    return {
      line_items: [{
        price: this.creditProduct?.default_price, quantity: this.slideController.value,
      }],
      mode: 'payment'
    }
  }


  async pay() {
    this.paymentInitiated = true;
    const res = await this.apiService.initiatePaymentSession(this.payload);
    if (res?.success) {
      // Save the payment id in localstorage
      this.storage.set("session_id", res.data.id);
      // Redirect to provided link
      window.location = res.data.location;
    }
  }

  protected readonly String = String;
}
