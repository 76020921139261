<h2 mat-dialog-title>Change your e-mail</h2>
<mat-dialog-content>
  <p>Please enter a valid e-mail address.</p>
  <p>A verification e-mail will be sent to the new address you provide.<br>Your records will only be updated once the new e-mail has been verified.</p>
  <p>You also need to provide your current password to authenticate this request.</p>
  <form [formGroup]="emailForm">
    <mat-form-field>
      <mat-label>New E-mail address</mat-label>
      <input matInput formControlName="email" type="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Current Password</mat-label>
      <input matInput formControlName="password" type="password">
    </mat-form-field>
    <mat-error>{{fbError}}</mat-error>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="emailForm.invalid || emailForm.untouched">Save</button>
</mat-dialog-actions>
