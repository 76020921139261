@if (!nav.hidden) {
  <nav>
    @if (userService.isLoggedIn() && !showExternal) {
      <a role="menuitem" routerLink="/home/ccq" [hidden]="!localeService.showService('ccq')">Calculate Cars Quicker</a>
      <a role="menuitem" routerLink="/home/cyc" [hidden]="!localeService.showService('cyc')">Check Your Car</a>
      <a role="menuitem" routerLink="/home/fcf" [hidden]="!localeService.showService('fcf')">Find Cars Faster</a>
      <a role="menuitem" routerLink="/ffl" [hidden]="!localeService.showService('ffl')">Forex For Less</a>
      <a role="menuitem" routerLink="/home/ctf" [hidden]="!localeService.showService('ctf')">Cars to Forecourt</a>
      <a role="menuitem" routerLink="/home/activity">Recent Activity</a>
      <a role="menuitem" routerLink="/home/admin" *ngIf="userService.admin">Admin</a>
    } @else {
      <a role="menuitem" routerLink="/ccq" [hidden]="!localeService.showService('ccq')">Calculate Cars Quicker</a>
      <a role="menuitem" routerLink="/cyc" [hidden]="!localeService.showService('cyc')">Check Your Car</a>
      <a role="menuitem" routerLink="/fcf" [hidden]="!localeService.showService('fcf')">Find Cars Faster</a>
      <a role="menuitem" routerLink="/tcspro" [hidden]="!localeService.showService('tcspro')">TheCarSite Pro</a>
      <a role="menuitem" routerLink="/ffl" [hidden]="!localeService.showService('ffl')">Forex For Less</a>
      <a role="menuitem" routerLink="/contact">Contact</a>
    }
  </nav>
}
