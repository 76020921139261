import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogModule, MatDialogTitle} from "@angular/material/dialog";
import {DecimalPipe, KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {formatRes} from "../../../pipes/format-res.pipe";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {RouterLink} from "@angular/router";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {UserService} from "../../../services/user.service";
import {QuotesService} from "../../../services/quotes.service";

@Component({
  selector: 'quote-details-dialog',
  templateUrl: 'quote-details-dialog.component.html',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, DecimalPipe, KeyValuePipe, NgForOf, NgIf, formatRes, MatButtonModule, MatDialogModule, NgClass, MatTooltipModule, MatSnackBarModule, MatProgressSpinner, RouterLink, MatOption, MatSelect],
})
export class QuoteDetailsDialog {
  protected readonly Number = Number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, protected userService: UserService, protected quoteService: QuotesService) {
  }

}
