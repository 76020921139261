import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {provideRouter, withInMemoryScrolling} from "@angular/router";
import { routes } from "./app.routes";
import { provideClientHydration } from "@angular/platform-browser";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from "@angular/fire/analytics";
import {environment} from "./environment/environment";
import {fairUseInterceptor} from "./interceptors/fair-use.interceptor";
import {authInterceptor} from "./interceptors/auth.interceptor";
import {NgxCookieConsentModule} from "@localia/ngx-cookie-consent";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";

const cookieConfig = {
  privacyPolicyUrl: '/privacy',
  imprintUrl: '/terms',
  showLanguageSwitcher: false,
  showCookieDetails: true,
  showMarketingCookies: false,
  essentialCookies: [
    {
      key: '_gcl_au',
      name: 'Firebase Authentication',
      description: 'Handles user accounts, registration and login',
      privacyPolicyUrl: 'https://firebase.google.com/support/privacy/',
      cookies: [
        {
          name: '_gcl_au',
          description: 'Handles user accounts, registration and login',
          duration: '1 year'
        }
      ]
    },
    {
      key: '_gcl_au',
      name: 'Cloudflare',
      description: 'Handles network and web security',
      privacyPolicyUrl: 'https://www.cloudflare.com/privacypolicy/',
      cookies: [
        {
          name: '_gcl_au',
          description: 'Provides CAPTCHA',
          duration: '1 year'
        }
      ]
    }
  ]
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withInMemoryScrolling({scrollPositionRestoration: "top"})),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(),withInterceptors([fairUseInterceptor,authInterceptor])),
    provideHttpClient(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    importProvidersFrom(NgxCookieConsentModule.forRoot(cookieConfig)),
    importProvidersFrom(MatNativeDateModule),
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
};
